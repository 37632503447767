
.first-last-container {
    display: flex;
}

.frt-lst-table-link h4 a{
    padding: 6px 16px;
    display: flex;
}

.first-last-container .first-last-label div {
    height: 32px;
    width: 60px;
    padding: 3px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-bottom: solid 1px #dddfe2;
    border-right: solid 1px #dddfe2;
    color: #ed1c25;
    font-weight: 400;
}

.first-last-container .first-last-label div:first-child {
    border-top: solid 1px #dddfe2;
    color: #000;
}


.first-last-container .first-last-value  {
    width: 100%;
    height: 32px;
    text-align: center;
}

.first-last-container .first-last-value div {
    height: 32px;
    border-bottom: solid 1px #dddfe2;
    border-right: solid 1px #dddfe2;
    text-align: left;
    font-weight: 600;
    padding: 3px;
}

.first-last-container .first-last-value div:first-child {
    border-top: solid 1px #dddfe2;
    text-align: center;
    font-weight: 400;

}


