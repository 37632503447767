button {
    border: none;
    border-radius: 4px;
    color: white;
    font-size: 14px;
    padding: 6px 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    margin-right: 10px;
}

button:last-of-type {
    margin-right: 0;
}


.button-save {
    margin-top: 8px;
    background-color: #4caf50;
}

.button-save:hover {
    background-color: #45a049;
}

.button-clear {
    margin-top: 8px;
    background-color: #f44336;
}

.button-clear:hover {
    background-color: #da190b;
}

.button-container {
    margin-top: 8px;
}

.prize-number  input {
    text-align: center;
}


.prize-number  input:disabled {
   cursor: not-allowed;
}
