body {
  margin: 0;
  padding: 0;
  line-height: 1.6;
  color: #000;
  font-family: "Roboto", sans-serif;
  font-weight: 400;
  font-size: 15px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
  background: white;
}

a {
  color: #0029ad;
  text-decoration: none;
}

a:hover {
  color: #ed1c25;
}

img {
  border: none;
  max-width: 100%;
}

ul,
li {
  margin: 0;
  padding: 0;
  list-style: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-family: sans-serif;
  margin: 0;
  padding: 0;
  line-height: 1.4;
}

p,
figure,
figcaption,
blockquote,
dl,
dt,
dd,
ol,
caption,
table,
tbody,
tfoot,
thead,
tr,
th,
td,
fieldset,
embed,
object,
applet,
canvas,
caption,
embed,
hgroup,
iframe,
output,
pre,
strike,
sub,
summary,
sup,
time,
video {
  border: 0;
  margin: 0;
  padding: 0;
}

* {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

::after,
::before {
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

::-webkit-input-placeholder {
  color: #555;
  opacity: 100;
}

:-ms-input-placeholder {
  color: #555;
  opacity: 100;
}

::placeholder {
  color: #bbb;
  opacity: 100;
}

:focus {
  outline: -webkit-focus-ring-color auto 0;
}

input:-webkit-autofill,
textarea:-webkit-autofill,
select:-webkit-autofill {
  box-shadow: 0 0 0 1000px white inset;
}

input::-ms-clear {
  display: none;
}

select::-ms-expand {
  display: none;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
  filter: alpha(opacity=0);
  opacity: 0;
}

input,
select,
textarea,
button {
  font-family: Roboto, sans-serif;
  font-size: 14px;
  font-weight: 400;
  line-height: inherit;
  outline: none;
}

input,
select {
  height: 40px;
  padding: 0 10px;
}

input,
select,
textarea {
  float: left;
  width: 100%;
  border: solid 1px #b3b3b3;
  margin: 0;
  box-shadow: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  text-overflow: "";
  appearance: none;
  border-radius: 2px;
  webkit-border-radius: 2px;
  -moz-border-radius: 2px;
}

input:focus,
select:focus,
textarea:focus {
  border: solid 1px #4285f4;
  outline: 0;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
    0 0 8px rgba(102, 175, 233, 0.6);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
    0 0 8px rgba(102, 175, 233, 0.6);
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

select {
  /* background: url(../) no-repeat; */
  background-position: right 8px top 50%;
  padding-right: 24px;
}

textarea {
  height: 85px;
  padding: 10px;
  resize: none;
}

button {
  background: none;
  cursor: pointer;
  border: 0;
  padding: 0;
  margin: 0;
}

button:focus {
  outline: 0;
}

table {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
}

table td,
table th {
  padding: 3px;
  border: solid 1px #dddfe2;
}

table thead th {
  background: #f0f8ff;
  font-size: 15px;
  font-weight: 500;
}

.form-group {
  float: left;
  width: 100%;
  position: relative;
  margin-bottom: 15px;
  display: table;
}

.form-group:last-child {
  margin-bottom: 0;
}

.btn-item {
  margin-right: 10px;
  float: left;
}

.btn-item:last-child {
  margin-right: 0;
}

.btn {
  display: inline-block;
  padding: 0 16px;
  height: 40px;
  line-height: 40px;
  background-color: #dddfe2;
  cursor: pointer;
  text-align: center;
  border-radius: 2px;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
}

.btn-full {
  width: 100%;
}

.btn-primary {
  background-color: #0029ad;
  color: #fff;
}

.btn-success {
  background-color: #28a745;
  color: #fff;
}

.btn-info {
  background-color: #33b5e5;
  color: #fff;
}

.btn-warning {
  background-color: #ffc107;
}

.btn-danger {
  background-color: #ed1c25;
  height: 40px;
  line-height: 40px;
  color: #fff;
}

.btn-secondary {
  background-color: #6c757d;
  color: #fff;
}

.btn-dark {
  background-color: #343a40;
  color: #fff;
}

.btn-outline {
  border: solid 2px #dddfe2;
  background-color: #fff;
  line-height: 36px;
}

.btn-primary-outline {
  border: solid 2px #0029ad;
  background-color: #fff;
  line-height: 36px;
}

.btn-success-outline {
  border: solid 2px #28a745;
  background-color: #fff;
  line-height: 36px;
}

.btn-info-outline {
  border: solid 2px #33b5e5;
  background-color: #fff;
  line-height: 36px;
}

.btn-warning-outline {
  border: solid 2px #ffc107;
  background-color: #fff;
  line-height: 36px;
}

.btn-danger-outline {
  border: solid 2px #ed1c25;
  background-color: #fff;
  line-height: 36px;
}

.btn-secondary-outline {
  border: solid 2px #6c757d;
  background-color: #fff;
  height: 40px;
  line-height: 36px;
}

.btn-dark-outline {
  border: solid 2px #343a40;
  background-color: #fff;
  line-height: 36px;
}

.text-danger {
  width: 100%;
  float: left;
  font-size: 12px;
  text-align: right;
  color: #ed1c25;
  padding-top: 5px;
  font-style: italic;
}

.text-success {
  width: 100%;
  float: left;
  font-size: 12px;
  text-align: right;
  color: #28a745;
  padding-top: 5px;
  font-style: italic;
}

.focus-danger {
  border: 0.5px solid #ed1c25;
  outline: 0;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
    0 0 8px rgba(102, 175, 233, 0.6);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
    0 0 8px rgba(102, 175, 233, 0.6);
}

.focus-success {
  border: 0.5px solid #28a745;
  outline: 0;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
    0 0 8px rgba(102, 175, 233, 0.6);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
    0 0 8px rgba(102, 175, 233, 0.6);
}

.focus-danger:focus {
  border: 0.5px solid #ed1c25;
  outline: 0;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
    0 0 8px rgba(102, 175, 233, 0.6);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
    0 0 8px rgba(102, 175, 233, 0.6);
}

.focus-success:focus {
  border: 0.5px solid #28a745;
  outline: 0;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
    0 0 8px rgba(102, 175, 233, 0.6);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
    0 0 8px rgba(102, 175, 233, 0.6);
}

.btn-right {
  float: right;
}

.label-input {
  display: block;
  position: relative;
  padding-left: 30px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.input-1 {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  display: none;
  left: 0;
  right: 0;
  width: 1px;
  height: 1px;
  margin: 0;
  padding: 0;
  top: 0;
}

.input-2 {
  position: absolute;
  top: 0;
  left: 0;
  height: 18px;
  width: 18px;
  background-color: #fff;
  border: solid 2px #0029ad;
}

.input-2:after {
  left: 4px;
  top: 2px;
  width: 6px;
  height: 9px;
  border: solid #fff;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.input-1 ~ .input-2 {
  background-color: #fff;
  border: solid 2px #dddfe2;
  border-radius: 2px;
}

.input-1:checked ~ .input-2 {
  background-color: #0029ad;
  border: solid 2px #0029ad;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
    0 0 8px rgba(102, 175, 233, 0.6);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
    0 0 8px rgba(102, 175, 233, 0.6);
}

.input-2:after {
  content: "";
  position: absolute;
  display: none;
}

.input-1:checked ~ .input-2:after {
  display: block;
}

.label-radio {
  display: block;
  position: relative;
  padding-left: 30px;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  line-height: 20px;
}

.radio-1 {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  display: none;
  left: 0;
  right: 0;
  width: 10px;
  margin: 0;
  padding: 0;
  top: 0;
  height: 10px;
}

.radio-2 {
  position: absolute;
  top: 0;
  left: 0;
  height: 18px;
  width: 18px;
  background-color: #fff;
  border: solid 2px #dddfe2;
  border-radius: 100%;
}

.radio-2:after {
  top: 2px;
  left: 2px;
  width: 10px;
  height: 10px;
  border-radius: 100%;
  background-color: #0029ad;
}

.labelradio:hover .radio-1 ~ .radio-2 {
  background-color: #fff;
}

.radio-1:checked ~ .radio-2 {
  background-color: #fff;
  border: solid 2px #0029ad;
  -webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
    0 0 8px rgba(102, 175, 233, 0.6);
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075),
    0 0 8px rgba(102, 175, 233, 0.6);
}

.radio-2:after {
  content: "";
  position: absolute;
  display: none;
}

.radio-1:checked ~ .radio-2:after {
  display: block;
}

.main {
  float: left;
  width: 100%;
  position: relative;
  margin-bottom: 20px;
  background-color: white;
  background: white;
}

.main-content {
  margin: auto;
  width: 100%;
  max-width: 1140px;
  min-width: 240px;
  background-color: white;
  background: white;
}

.content-left {
  float: left;
  width: 53.5%;
  position: relative;
  padding-right: 16px;
}

@media only screen and (max-width: 1024px) {
  .main-content {
    max-width: 1000px;
  }

  .content-left {
    width: 52.5%;
  }
}

@media only screen and (min-width: 2100px) {
  .radio-2 {
    width: 16px;
    height: 16px;
  }
}

.header {
  float: left;
  width: 100%;
  position: relative;
  margin-bottom: 16px;
}

.header-pc {
  float: left;
  width: 100%;
}

.header-content {
  float: left;
  width: 100%;
  padding: 5px 0;
  background: #fff;
}

.main-content.padding0 {
  padding: 0;
}

.header-logo {
  float: left;
}

.header-logo-img {
  float: left;
}

.header-right {
  float: left;
  margin-left: 20px;
  border-left: solid 1px #e0e0e0;
  padding-left: 21px;
  margin-top: 14px;
}

.header-mobi {
  background: #fff;
  height: 56px;
  float: left;
  width: 100%;
  display: none;
}

.logo-mobi {
  position: absolute;
  left: 48px;
  margin-top: 12px;
}

.logo-mobi-img {
  height: 32px;
}

.icon-mobi-right {
  float: left;
  margin-top: 12px;
  margin-right: 10px;
}

.btn-dropdown1.mg0 {
  margin: 0;
}

.fix-nav-mobi {
  padding: 10px 20px;
}

.icon-menu {
  top: 2px;
  position: relative;
}

.header-time {
  float: right;
  color: #666;
  font-size: 14px;
  font-family: sans-serif;
}

.btn-calendar {
  display: none;
  position: absolute;
  right: 16px;
  top: 10px;
  width: 36px;
  height: 36px;
  text-align: center;
}

.icon-calendar {
  position: relative;
  top: 8px;
}

@media only screen and (max-width: 960px) {
  .header {
    margin-bottom: 12px;
  }

  .header-content {
    background: #fff;
    position: relative;
    z-index: 9;
  }

  .content-left {
    width: 100%;
    padding: 0;
  }

  .content-right {
    width: 100%;
    float: left;
  }

  .header-mobi {
    display: block;
  }

  .headermobi {
    padding: 0;
    height: 56px;
  }

  .logomobi {
    margin-top: 12px;
  }

  .header-logo-img {
    float: none;
    height: 36px;
    top: 12px;
    position: relative;
  }

  .header-logo {
    float: left;
    width: 100%;
    text-align: center;
  }

  .btn-calendar {
    display: block;
  }

  .header-time {
    display: none;
  }

  .maxheight_750_mobile {
    max-height: 750px;
  }
}

.aside-300 {
  float: right;
  width: 27%;
  position: relative;
  overflow: hidden;
}

.aside-160 {
  float: left;
  width: 18%;
  position: relative;
}

@media only screen and (max-width: 1024px) {
  .aside-300 {
    width: 30%;
  }

  .aside-160 {
    width: 16%;
  }
}

.widget {
  float: left;
  width: 100%;
  margin-bottom: 16px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  -webkit-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16),
    0 2px 10px 0 rgba(0, 0, 0, 0.12);
  -moz-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16),
    0 2px 10px 0 rgba(0, 0, 0, 0.12);
}

.widget-title {
  float: left;
  width: 100%;
  position: relative;
  padding: 10px 12px;
  background: #f0f8ff;
  text-transform: uppercase;
  border-bottom: solid 1px #eaeaea;
}

.widget-title a {
  color: #000;
}

.widget-container {
  float: left;
  width: 100%;
  position: relative;
}

.post-title-widget {
  font-size: 16px;
}

.widget-news {
  padding: 10px 0;
  border-bottom: solid 1px #dddfe2;
}

.widget-news:first-child {
  padding-top: 0;
}

.widget-news:last-child {
  padding-bottom: 0;
  border-bottom: 0;
}

.widget-title h3 {
  font-size: 13px;
}

.link-item a {
  font-family: sans-serif;
  float: left;
  width: 100%;
  padding: 6px 12px 6px 24px;
  position: relative;
}

.link-item a:hover {
  background: #f0f8ff;
}

.link-item a::before {
  border-top: 4px dashed;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
  content: "";
  position: absolute;
  left: 10px;
  top: 17px;
  color: #444;
  -webkit-transform: rotate(-90deg);
  -moz-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  -o-transform: rotate(-90deg);
  transform: rotate(-90deg);
}

.link-item2 {
  font-family: sans-serif;
  float: left;
  width: 100%;
  padding: 6px 12px 6px 24px;
  position: relative;
  font-weight: 500;
  color: #222;
}

.link-item2:hover {
  background: #f0f8ff;
}

.icon-right {
  position: absolute;
  left: 12px;
  top: 12px;
}

.link-item2-sub a {
  font-family: sans-serif;
  float: left;
  width: 100%;
  padding: 5px 5px 5px 24px;
  color: #222;
}

.link-item2-sub a:hover {
  background: #f0f8ff;
}

.link-item2::before {
  border-top: 4px dashed;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
  content: "";
  position: absolute;
  left: 10px;
  top: 17px;
  color: #444;
  -webkit-transform: rotate(-90deg);
  -moz-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  -o-transform: rotate(-90deg);
  transform: rotate(-90deg);
}

.widget_bg {
  background: #c80505;
  color: #fff;
}

.form-wg {
  width: 100%;
  float: left;
  padding: 16px;
}

.label_gril {
  float: left;
  line-height: 40px;
  width: 70px;
}

.input_gril {
  display: grid;
}

.tutorial-loto {
  color: #555;
  font-size: 13px;
  line-height: 22px;
  font-style: italic;
}

.form-select {
  padding: 10px;
  float: left;
  width: 100%;
}

.select-item {
  float: left;
  width: 66px;
  height: 36px;
  margin-right: 5px;
  padding: 0 5px;
}

.btn-select {
  height: 36px;
  line-height: 36px;
  padding: 0 10px;
  cursor: pointer;
}

.btn-list1 {
  float: left;
  width: 24px;
  height: 36px;
  margin-right: 5px;
  cursor: pointer;
}

.btn-list2 {
  float: right;
  width: 24px;
  height: 36px;
  text-align: center;
  cursor: pointer;
}

.img-c {
  position: relative;
  top: 7px;
}

.caleandar-weks {
  float: left;
  width: 100%;
  background: #f0f8ff;
}

.caleandar-right {
  position: absolute;
  right: 0;
  top: 8px;
}

.caleandar-left {
  float: left;
  color: #fff;
  line-height: 36px;
}

.iconleft {
  cursor: pointer;
  height: 36px;
  width: 36px;
  line-height: 36px;
  float: left;
  text-align: center;
  color: #fff;
}

.content-caleandar {
  float: left;
  max-width: 400px;
  position: relative;
  border-radius: 2px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  -webkit-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16),
    0 2px 10px 0 rgba(0, 0, 0, 0.12);
  -moz-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16),
    0 2px 10px 0 rgba(0, 0, 0, 0.12);
}

.caleandar-days {
  float: left;
  width: 100%;
  position: relative;
  display: table;
}

.title-caleandar {
  float: left;
  text-align: left;
  width: 100%;
  color: #666;
  background: #4285f4;
  padding: 10px;
  position: relative;
  border-radius: 2px 2px 0 0;
}

.caleandar-weks li {
  color: #000;
  float: left;
  width: 14.286%;
  padding: 4px;
  display: table-cell;
  border-left: solid 1px #e0e0e0;
  border-top: solid 1px #e0e0e0;
  text-align: center;
  font-size: 13px;
}

.caleandar-days li {
  color: #000;
  float: left;
  width: 14.286%;
  padding: 4px;
  display: table-cell;
  height: 50px;
  border-left: solid 1px #e0e0e0;
  border-top: solid 1px #e0e0e0;
  cursor: pointer;
}

.caleandar-days li:hover {
  background: #ffeca0;
}

.caleandar-days li:nth-child(7n + 7) {
  color: #ff3547;
}

.caleandar-days li.bgnone:hover {
  background: #f0f8ff;
}

.caleandar-days li.active {
  background: #ffeca0;
}

.ca-pm {
  float: left;
  font-size: 16px;
  width: 100%;
  text-align: left;
  font-weight: 500;
  display: contents;
}

.ca-am {
  float: left;
  width: 100%;
  text-align: right;
  font-size: 12px;
  color: #999;
}

.icon-hacdao {
  float: right;
  background: #999;
  height: 4px;
  margin-top: 4px;
  width: 4px;
  border-radius: 100%;
}

.icon-hoangdao {
  margin-top: 4px;
  float: right;
  background: #ff3547;
  height: 4px;
  width: 4px;
  border-radius: 100%;
}

.icon_pm_am {
  float: right;
}

.disabled-ca {
  background: #f0f8ff;
  color: #666;
}

.disabled-ca:hover {
  color: #666;
  background: #f0f8ff !important;
}

.disabled-ca .ca-pm {
  color: #666;
}

@media only screen and (max-width: 960px) {
  .widget {
    margin-bottom: 12px;
  }

  .aside-300 {
    float: left;
    width: 100%;
    display: none;
  }

  .aside-160 {
    width: 100%;
    display: none;
  }
}

.user-sidebar {
  background: #f0f8ff;
  position: relative;
  padding: 0 16px 10px 16px;
  float: left;
  width: 100%;
}

.logosidebar {
  height: 48px;
}

.pushbar_locked {
  overflow: hidden;
  height: 100vh;
  position: fixed;
  width: 100%;
}

.pushbar {
  background: #fff;
  z-index: 99999;
  position: fixed;
  will-change: transform;
  overflow-y: auto;
  transition: transform 0.5s ease;
  will-change: transform;
}

.pushbar_overlay {
  z-index: -999;
  position: fixed;
  width: 100%;
  max-width: 100%;
  height: 100%;
  min-height: 100vh;
  top: 0;
  left: 0;
  will-change: opacity;
  opacity: 0;
  will-change: opacity;
  background: #3c3442;
}

.pushbar_locked .pushbar_overlay {
  opacity: 0.8;
  z-index: 999;
  transition: opacity 0.5s ease;
}

.pushbar.from_left {
  top: 0;
  left: 0;
  width: 300px;
  max-width: 100%;
  height: 100%;
  overflow-x: hidden;
  transform: translateZ(0) translateX(-100%);
}

.pushbar.opened {
  transform: translateX(0) translateY(0);
}

.btn-pushbar-close {
  height: 48px;
  position: relative;
  z-index: 99;
  font-size: 30px;
  cursor: pointer;
  background: #f0f8ff;
  width: 100%;
  float: left;
}

.icon-bacck {
  float: left;
  width: 48px;
  text-align: center;
  line-height: 48px;
}

.btn-pushbar-3 {
  cursor: pointer;
  position: absolute;
  left: 8px;
  top: 10px;
  width: 36px;
  height: 36px;
  line-height: 36px;
  text-align: center;
  border-radius: 100%;
  display: none;
}

.pushbar-hide {
  display: none;
}

.icon-back {
  height: 16px;
  width: 16px;
  position: relative;
  left: 16px;
  top: -2px;
}

@media only screen and (max-width: 960px) {
  .pushbar-hide {
    display: block;
  }

  .btn-pushbar-3 {
    display: block;
  }
}

.ac-menu {
  width: 100%;
  float: left;
}

.ac_item {
  position: relative;
  width: 100%;
  float: left;
}

.ac_item:last-child {
  border-bottom: 0;
}

.ac_title {
  float: left;
  padding: 12px 0;
  width: 100%;
  padding-right: 36px;
  border-top: solid 1px #dddfe2;
  font-weight: 500;
}

.ac_content {
  display: none;
  width: 100%;
  float: left;
}

.ac_list {
  padding: 10px 0;
  float: left;
  width: 100%;
}

.ac_icon {
  cursor: pointer;
  display: block;
  position: absolute;
  right: 10px;
  background-size: 12px;
  width: 36px;
  height: 36px;
  z-index: 2;
  top: 6px;
}

.ac_open .ac_icon::before {
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
  transition: all 0.4s ease-out;
  -webkit-transition: all 0.4s ease-out;
}

.ac_icon::before {
  border-top: 4px dashed;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
  content: "";
  left: 40%;
  top: 50%;
  position: absolute;
  color: #666;
  transition: all 0.4s ease-out;
  -webkit-transition: all 0.4s ease-out;
}

.ac_title_2 {
  font-size: 16px;
  float: left;
  padding: 12px 36px 12px 56px;
  width: 100%;
  font-weight: 500;
  color: #222;
}

.ac_ul2 {
  padding-bottom: 10px;
  float: left;
  width: 100%;
}

.ac_ul2 a {
  padding: 10px 36px 10px 56px;
  float: left;
  width: 100%;
  color: #222;
}

.ic-m {
  width: 24px;
  float: left;
  position: absolute;
  left: 16px;
}

.ac_item.boder_top {
  border-top: solid 1px #eaeaea;
}

.ac_item.ac_open .ac_title_2 {
  color: #ed1c25;
}

.menu_ul {
  width: 100%;
  margin: auto;
  max-width: 1140px;
}

.menu_li {
  float: left;
  position: relative;
}

.menu_a {
  font-family: sans-serif;
  padding: 0 14px;
  float: left;
  width: 100%;
  height: 48px;
  line-height: 48px;
  font-size: 14px;
  text-transform: uppercase;
  color: #fff;
  font-weight: bold;
  -webkit-transition: color 0.2s linear, background 0.2s linear;
  -moz-transition: color 0.2s linear, background 0.2s linear;
  -o-transition: color 0.2s linear, background 0.2s linear;
  transition: color 0.2s linear, background 0.2s linear;
}

.menu_a:hover {
  background: #ed1c25;
  color: #fff;
}

.menu_a.active {
  background: #ed1c25;
  color: #fff;
}

.menu_down li {
  width: 100%;
}

.menu_down {
  width: 180px;
  background: #fff;
  position: absolute;
  opacity: 0;
  visibility: hidden;
  z-index: 99999;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  -webkit-transition: opacity 0.2s linear, visibility 0.2s linear;
  -moz-transition: opacity 0.2s linear, visibility 0.2s linear;
  -o-transition: opacity 0.2s linear, visibility 0.2s linear;
  transition: opacity 0.2s linear, visibility 0.2s linear;
  top: 48px;
  left: 0;
}

.menu_down a {
  padding: 8px 15px;
  float: left;
  width: 100%;
  position: relative;
  color: #222;
}

.menu_down a:hover {
  background: #f0f8ff;
}

.menu_down a.active {
  background: #f0f8ff;
}

.icright {
  font-size: 16px;
  position: absolute;
  right: 15px;
  top: 13px;
}

.ic-down-menu {
  font-size: 12px;
  margin-left: 5px;
}

.icon-menu2 {
  position: relative;
  top: 3px;
}

.icon-home {
  width: 17px;
  height: 16px;
  max-width: 17px;
  position: relative;
  top: 2px;
}

@media only screen and (max-width: 1140px) {
  .menu_ul {
    overflow-y: hidden;
    -webkit-overflow-scrolling: touch;
    overflow-x: scroll;
    overflow-x: auto;
    white-space: nowrap;
    display: flex;
  }

  .menu_li {
    display: table-cell;
    text-align: center;
    position: inherit;
  }

  .menu_a {
    padding: 0 14px;
    float: left;
    width: 100%;
    height: 48px;
    line-height: 48px;
    font-size: 14px;
    text-transform: uppercase;
    color: #fff;
    font-weight: bold;
    -webkit-transition: color 0.2s linear, background 0.2s linear;
    -moz-transition: color 0.2s linear, background 0.2s linear;
    -o-transition: color 0.2s linear, background 0.2s linear;
    transition: color 0.2s linear, background 0.2s linear;
  }

  .menu_a:hover {
    background: #ed1c25;
    color: #fff;
  }

  .menu_a.active {
    background: #ed1c25;
    color: #fff;
  }

  .menu_down {
    display: none;
  }

  .ic-down-menu {
    display: none;
  }
}

.nav_header {
  background: #c80505;
  float: left;
  width: 100%;
  position: relative;
}

.nav_hidden {
  transform: translateY(-100%);
  -webkit-transform: translateY(-100%);
  -moz-transform: translateY(-100%);
  -o-transform: translateY(-100%);
  transition: transform 300ms ease;
  -webkit-transition: transform 300ms ease;
  -moz-transition: transform 300ms ease;
  -o-transition: transform 300ms ease;
}

.nav_show {
  z-index: 99;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  transform: translateY(0);
  -webkit-transform: translateY(0);
  -moz-transform: translateY(0);
  -o-transform: translateY(0);
  transition: transform 300ms ease;
  -webkit-transition: transform 300ms ease;
  -moz-transition: transform 300ms ease;
  -o-transition: transform 300ms ease;
}

.nav-scrol-link-ic {
  position: absolute;
  cursor: pointer;
  left: 0;
  width: 40px;
  text-align: center;
  background: #c80505;
  z-index: 99;
  line-height: 48px;
  height: 48px;
  color: #fff;
  display: none;
}

@media only screen and (max-width: 960px) {
  .nav_header {
    background: #c80505;
  }

  .hide {
    display: none;
  }

  .nav_show .nav-scrol-link-ic {
    display: block;
  }

  .nav_show .menu_ul {
    padding-left: 40px;
  }
}

.footer {
  float: left;
  width: 100%;
  position: relative;
  background: #f0f8ff;
  border-top: solid 1px #dddfe2;
}

.footer-content {
  padding: 15px 0;
  float: left;
  width: 100%;
}

.footer-title {
  float: left;
  width: 100%;
}

.footer-container {
  float: left;
  width: 100%;
}

.copyright {
  float: left;
  font-size: 13px;
  line-height: 24px;
  width: 70%;
}

.share-socal {
  float: right;
  position: relative;
  width: 30%;
  text-align: center;
}

.rows-add {
  position: relative;
  padding-top: 16px;
  width: 100%;
  float: left;
}

.textic24 {
  font-weight: bold;
}

.ic24 {
  position: relative;
  top: 5px;
  margin-right: 5px;
}

.dmca_protected {
  margin-left: 10px;
  float: left;
  margin-top: 5px;
}

.mxh-like-item {
  float: left;
  margin-left: 10px;
}

.link-web {
  color: #999;
  float: left;
  font-size: 13px;
  width: 100%;
}

.link-web > a {
  color: #555;
  font-size: 13px;
}

.icon-face {
  border-radius: 100%;
  float: left;
  color: #fff;
  height: 30px;
  line-height: 30px;
  text-align: center;
  width: 30px;
}

.icon-face.cl1 {
  background: #134a8d;
  color: #fff;
}

.icon-face.cl2 {
  background: #ed3b3b;
  color: #fff;
}

.icon-face.cl3 {
  background: #10afe3;
  color: #fff;
}

.nav-bottom a {
  margin-right: 16px;
  color: #000;
}

.nav-bottom {
  padding-top: 16px;
  font-size: 14px;
  font-weight: 500;
}

.backtotop {
  background-color: #ed1c25;
  position: fixed;
  bottom: 10px;
  right: 10px;
  z-index: 999;
  border-radius: 100%;
  height: 48px;
  width: 48px;
  line-height: 48px;
  text-align: center;
  box-shadow: 0 1px 6px 0 rgba(32, 33, 36, 0.28);
  display: none;
  animation: animatezoom 0.6s;
}

@keyframes animatezoom {
  from {
    transform: scale(0);
  }

  to {
    transform: scale(1);
  }
}

.top-arrow {
  position: relative;
  top: 3px;
}

.show_backtotop {
  display: block;
}

@media only screen and (max-width: 960px) {
  .footer {
    padding: 0 12px;
  }

  .nav-bottom {
    padding-top: 8px;
  }

  .nav-bottom a {
    padding-top: 10px;
    display: inline-block;
  }

  .copyright {
    width: 100%;
  }

  .share-socal {
    float: left;
    width: 100%;
  }

  .backtotop {
    bottom: 90px;
  }
}

.section {
  float: left;
  width: 100%;
  margin-bottom: 16px;
  position: relative;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  -webkit-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16),
    0 2px 10px 0 rgba(0, 0, 0, 0.12);
  -moz-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16),
    0 2px 10px 0 rgba(0, 0, 0, 0.12);
}

input[type="date"]::-webkit-clear-button,
input[type="date"]::-webkit-inner-spin-button {
  display: none;
}

.section:last-child {
  margin-bottom: 0;
}

.section-header {
  float: left;
  width: 100%;
  position: relative;
  background: #ffeca0;
  border: solid 1px #dddfe2;
  border-bottom: 0;
  padding: 8px 10px;
  text-align: center;
}

.section-header h1 {
  font-family: sans-serif;
  font-size: 22px;
  text-align: center;
  font-weight: bold;
}

.section-header h2 {
  font-size: 22px;
  text-align: center;
  font-weight: bold;
}

.section-header h2 > a {
  color: #ed1c25;
}

.section-header h2 > a:hover {
  color: #0029ad;
}

.section-header2 {
  float: left;
  width: 100%;
  position: relative;
  background: #f0f8ff;
  border: solid 1px #dddfe2;
  padding: 8px 10px;
}

.section-header2 h1 {
  font-size: 16px;
  font-weight: bold;
}

.section-header2 h2 {
  font-size: 16px;
  font-weight: bold;
}

.section-header2 h2 > a {
  color: #ed1c25;
}

.section-header2 h2 > a:hover {
  color: #0029ad;
}

.section-content {
  float: left;
  width: 100%;
  position: relative;
}

.line-clamp1 {
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 1;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}

.line-clamp2 {
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 2;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}

.line-clamp3 {
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 3;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}

.line-clamp4 {
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 4;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}

.line-clamp5 {
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 5;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}

.page-head {
  float: left;
  width: 100%;
}

.post-listing {
  float: left;
  width: 100%;
}

.post-title {
  font-size: 16px;
  margin-bottom: 10px;
}

.post-title a {
  color: #000;
}

.post-title a:hover {
  color: #ed1c25;
}

.post-sapo {
  font-size: 15px;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 3;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}

.post-sapo p {
  color: #333;
}

.font20 {
  font-size: 20px;
}

.post-tags {
  padding-top: 5px;
}

.post-tags-item {
  border: solid 1px #dddfe2;
  border-radius: 3px;
  color: #757575;
  font-size: 12px;
  padding: 3px 10px;
  float: left;
  margin-right: 5px;
}

.post-date {
  font-size: 13px;
  color: #666;
}

.more-link {
  float: left;
  width: 100%;
}

.more-link-left {
  float: left;
}

.more-link-right {
  float: right;
}

.color_white {
  color: #fff;
}

.color_red {
  color: #ed1c25;
}

.color_blue {
  color: #0029ad;
}

.article-news {
  float: left;
  width: 100%;
  position: relative;
  padding: 16px;
  border-bottom: solid 1px #eaeaea;
}

.post-content {
  display: grid;
}

.post-grid {
  display: table;
}

.post {
  display: grid;
}

.article-title {
  margin-bottom: 5px;
  font-size: 18px;
}

.article-summary {
  color: #333;
  margin-bottom: 5px;
  overflow: hidden;
  text-overflow: ellipsis;
  -webkit-line-clamp: 3;
  display: -webkit-box;
  -webkit-box-orient: vertical;
}

.article-meta {
  font-size: 12px;
  color: #666;
  margin-bottom: 5px;
}

.time-publish {
  margin-right: 5px;
}

.category-parent {
  margin-right: 5px;
  text-transform: uppercase;
}

.article-count {
  font-size: 12px;
  color: #666;
  margin-bottom: 5px;
}

.like-count {
  margin-right: 5px;
}

.rating-count {
  margin-right: 5px;
}

.comment-count {
  margin-right: 5px;
}

.article-tags {
  margin-bottom: 5px;
}

.article-tags a {
  display: inline-block;
  border: solid 1px #e0e0e0;
  border-radius: 2px;
  color: #757575;
  font-size: 12px;
  padding: 3px 10px;
}

header div:last-child {
  margin-bottom: 0;
}

.article-list {
  float: left;
  width: 100%;
  position: relative;
  padding: 16px;
  border-bottom: solid 1px #e0e0e0;
}

.article-inner {
  position: relative;
  float: left;
  width: 100%;
}

.article-related {
  float: left;
  width: 100%;
}

.article-related li {
  padding: 12px 0;
  padding-left: 16px;
  position: relative;
  border-bottom: solid 1px #e0e0e0;
  float: left;
  width: 100%;
}

.article-related li:before {
  position: absolute;
  content: "";
  left: 0;
  top: 20px;
  border-top: 4px dashed;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
  color: #444;
  -webkit-transform: rotate(-90deg);
  -moz-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  -o-transform: rotate(-90deg);
  transform: rotate(-90deg);
}

.article-link {
  float: left;
  width: 100%;
}

.article-link li {
  padding: 12px 0;
  padding-left: 16px;
  position: relative;
  border-bottom: solid 1px #e0e0e0;
  float: left;
  width: 100%;
}

.article-link li:before {
  content: "â€¢";
  display: inline-block;
  position: absolute;
  left: 0;
  top: 10px;
  color: #333;
  font-size: 18px;
}

.image {
  width: 100%;
  float: left;
  object-fit: cover;
}

.description {
  display: grid;
}

.description_p15 {
  padding-left: 15px;
}

.post-news li {
  float: left;
  width: 100%;
  padding: 10px 16px;
  border-bottom: solid 1px #dddfe2;
  position: relative;
}

.post-title2 {
  font-size: 16px;
  font-weight: 400;
}

.post-news a {
  color: #000;
}

.post-news a:hover {
  color: #ed1c25;
}

.news-item-inner {
  position: relative;
  float: left;
  width: 100%;
}

.primary-color {
  color: #0029ad;
}

.success-color {
  color: #28a745;
}

.danger-color {
  color: #ed1c25;
}

.ads {
  float: left;
  width: 100%;
  text-align: center;
  position: relative;
  margin-bottom: 16px;
}

.ads img {
  max-width: 100%;
}

.google-ads {
  width: 100%;
  float: left;
  text-align: center;
  margin: 12px 0;
}

.google-ads img {
  max-width: 100%;
}

.breadcrumb {
  font-family: sans-serif;
  float: left;
  width: 100%;
  margin-bottom: 10px;
  color: #555;
  font-size: 13px;
}

.breadcrumb a {
  display: contents;
  color: #555;
}

.breadcrumb a + a:before {
  padding: 0 5px;
  color: #555;
  content: "/Â ";
}

.breadcrumb a + span:before {
  padding: 0 5px;
  color: #555;
  content: "/Â ";
}

.flex-center {
  display: flex;
  justify-content: center;
}

.flex-end {
  display: flex;
  justify-content: flex-end;
}

.flex-start {
  display: flex;
  justify-content: flex-start;
}

.gridcenter {
  display: grid;
  place-items: center;
  align-items: center;
  justify-items: center;
  width: 100%;
  height: 100%;
  position: absolute;
}

.text-center {
  text-align: center;
}

.text-left {
  text-align: left;
}

.text-right {
  text-align: right;
}

.pagination {
  float: left;
  width: 100%;
}

.pagination-pages {
  margin-left: 10px;
  float: left;
  height: 36px;
  text-align: center;
  line-height: 36px;
  border-radius: 2px;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border: solid 1px #dddfe2;
  padding: 0 5px;
  min-width: 36px;
}

.pagination-pages:first-child {
  margin-left: 0;
}

.pagination-pages:hover {
  background-color: #0029ad;
  color: #fff;
  border: solid 1px #0029ad;
}

.pagination-pages.active {
  background-color: #0029ad;
  color: #fff;
  border: solid 1px #0029ad;
}

.see-more {
  float: left;
  width: 100%;
  text-align: center;
  padding: 12px 16px;
}

.see-more.top0 {
  padding-top: 0;
}

.see-more a {
  border: solid 1px #e0e0e0;
  padding: 4px 16px;
  display: inline-block;
  border-radius: 25px;
  color: #666;
}

.load-more {
  float: left;
  width: 100%;
  text-align: center;
  margin-bottom: 16px;
}

.load-more a {
  color: #fff;
  padding: 8px 25px;
  display: inline-block;
  border-radius: 25px;
  background: #ed1c25;
}

.article-image {
  float: left;
  margin-right: 12px;
  position: relative;
  overflow: hidden;
}

.post-thumbnail {
  float: left;
  margin-right: 12px;
  position: relative;
  overflow: hidden;
}

.thumb {
  margin-bottom: 10px;
  float: left;
  width: 100%;
  position: relative;
  overflow: hidden;
}

.thumb-full {
  width: 100%;
  float: left;
  object-fit: cover;
}

.thumb200 {
  width: 200px;
  height: 112px;
}

.thumb120 {
  width: 120px;
  float: left;
  margin-right: 12px;
  position: relative;
  overflow: hidden;
}

.thumb120 img {
  width: 100%;
  float: left;
  object-fit: cover;
}

.thumb80 {
  width: 80px;
}

.thumb-scale:hover {
  -webkit-transform: scale(1.2);
  -moz-transform: scale(1.2);
  -o-transform: scale(1.2);
  transform: scale(1.2);
  transition: all 0.3s;
  -webkit-transition: all 0.3s;
}

.article-news h2 {
  font-size: 18px;
  padding-bottom: 8px;
}

.article-news h3 {
  font-size: 18px;
  padding-bottom: 8px;
}

.first-news {
  font-size: 22px !important;
}

@media only screen and (max-width: 960px) {
  .breadcrumb {
    font-size: 13px;
    padding: 0 16px;
  }

  .section {
    margin-bottom: 12px;
    background: #fff;
  }

  .thumb200 {
    width: 120px;
  }

  .ads {
    margin-bottom: 12px;
  }

  .first-news {
    font-size: 20px !important;
  }

  .thumb200 {
    width: 120px;
    height: 67px;
  }

  .box-live {
    padding-left: 10px;
  }
}

.col1 {
  float: left;
  width: 100%;
  position: relative;
}

.col2 {
  float: left;
  width: 48.5%;
  margin-right: 3%;
  position: relative;
}

.col2:nth-child(2n + 2) {
  margin-right: 0;
}

.col3 {
  float: left;
  width: 31.3%;
  margin-right: 3%;
  position: relative;
}

.col3:nth-child(3n + 3) {
  margin-right: 0;
}

.col4 {
  float: left;
  width: 22.7%;
  margin-right: 3%;
  position: relative;
}

.col4:nth-child(4n + 4) {
  margin-right: 0;
}

.col5 {
  float: left;
  width: 17.6%;
  margin-right: 3%;
  position: relative;
}

.col5:nth-child(5n + 5) {
  margin-right: 0;
}

.column1 {
  float: left;
  width: 100%;
  position: relative;
}

.column2 {
  float: left;
  width: 48.5%;
  margin-right: 3%;
  position: relative;
}

.column2:nth-child(2n + 2) {
  margin-right: 0;
}

.column3 {
  float: left;
  width: 31.3%;
  margin-right: 3%;
  position: relative;
}

.column3:nth-child(3n + 3) {
  margin-right: 0;
}

.column4 {
  float: left;
  width: 22.7%;
  margin-right: 3%;
  position: relative;
}

.column_fix {
  float: left;
  width: 22.7%;
  margin-right: 3%;
  position: relative;
}

.column4:nth-child(4n + 4) {
  margin-right: 0;
}

.column5 {
  float: left;
  width: 17.6%;
  margin-right: 3%;
  position: relative;
}

.column5:nth-child(5n + 5) {
  margin-right: 0;
}

@media only screen and (max-width: 960px) {
  .col2 {
    width: 100%;
    margin-right: 0;
  }

  .col3 {
    width: 100%;
    margin-right: 0;
  }

  .col4 {
    width: 100%;
    margin-right: 0;
  }

  .col5 {
    width: 100%;
    margin-right: 0;
  }

  .column_fix {
    width: 100%;
  }
}

.box-result {
  width: 100%;
  float: left;
}

.table-result {
  text-align: center;
  font-family: arial;
}

.table-result td,
.table-lottery th {
  padding: 0;
}

.table-result tr:nth-of-type(2n) {
  background-color: #f0f8ff;
}

.table-result thead th {
  background-color: #f0f8ff;
  font-weight: 500;
}

.table-result th {
  font-weight: normal;
}

.name-prize {
  width: 10%;
}

.number-prize {
  width: 90%;
}

.code-DB1 {
  float: left;
  width: 100%;
  height: 40px;
  line-height: 40px;
  font-size: 22px;
  color: #ed1c25;
  font-weight: bold;
}

.code-DB2 {
  float: left;
  width: 50%;
  height: 40px;
  line-height: 40px;
  font-size: 22px;
  color: #ed1c25;
  font-weight: bold;
}

.code-DB3 {
  float: left;
  width: 33.3%;
  height: 40px;
  line-height: 40px;
  font-size: 22px;
  color: #ed1c25;
  font-weight: bold;
}

.code-DB4 {
  float: left;
  width: 25%;
  height: 40px;
  line-height: 40px;
  font-size: 22px;
  color: #ed1c25;
  font-weight: bold;
}

.code-DB5 {
  float: left;
  width: 20%;
  height: 40px;
  line-height: 40px;
  font-size: 22px;
  color: #ed1c25;
  font-weight: bold;
}

.special-prize {
  float: left;
  width: 100%;
  height: 40px;
  line-height: 40px;
  font-size: 34px;
  font-weight: bold;
  color: #ed1c25;
}

.special-prize:hover {
  background-color: #fff4c3;
}

.prize1 {
  float: left;
  width: 100%;
  height: 40px;
  line-height: 40px;
  font-size: 28px;
  font-weight: bold;
}

.prize1:hover {
  background-color: #fff4c3;
}

.prize2 {
  float: left;
  width: 50%;
  height: 40px;
  line-height: 40px;
  font-size: 28px;
  font-weight: bold;
}

.prize2:hover {
  background-color: #fff4c3;
}

.prize3 {
  float: left;
  width: 33.3%;
  height: 40px;
  line-height: 40px;
  font-size: 28px;
  font-weight: bold;
}

.prize3:hover {
  background-color: #fff4c3;
}

.prize4 {
  float: left;
  width: 25%;
  height: 40px;
  line-height: 40px;
  font-size: 28px;
  font-weight: bold;
}

.prize4:hover {
  background-color: #fff4c3;
}

.prize5 {
  float: left;
  width: 33.3%;
  height: 40px;
  line-height: 40px;
  font-size: 28px;
  font-weight: bold;
}

.prize5:hover {
  background-color: #fff4c3;
}

.prize6 {
  float: left;
  width: 33.3%;
  height: 40px;
  line-height: 40px;
  font-size: 28px;
  font-weight: bold;
}

.prize6:hover {
  background-color: #fff4c3;
}

.prize7 {
  float: left;
  width: 25%;
  height: 40px;
  line-height: 40px;
  font-weight: bold;
  font-size: 34px;
  color: #ed1c25;
}

.prize7:hover {
  background-color: #fff4c3;
}

.prize-col2 {
  width: 40%;
}

.prize-col3 {
  width: 30%;
}

.prize-col4 {
  width: 22.5%;
}

.number_DB {
  float: left;
  width: 100%;
  height: 40px;
  line-height: 40px;
  font-size: 32px;
  font-weight: bold;
  color: #ed1c25;
}

.number_DB:hover {
  background-color: #fff4c3;
}

.xs_prize1 {
  float: left;
  width: 100%;
  height: 40px;
  line-height: 40px;
  font-size: 26px;
  font-weight: bold;
}

.xs_prize1:hover {
  background-color: #fff4c3;
}

.xs_prize2 {
  float: left;
  width: 50%;
  height: 40px;
  line-height: 40px;
  font-size: 26px;
  font-weight: bold;
}

.xs_prize2:hover {
  background-color: #fff4c3;
}

.xs_prize3 {
  float: left;
  width: 33.3%;
  height: 40px;
  line-height: 40px;
  font-size: 26px;
  font-weight: bold;
}

.xs_prize3:hover {
  background-color: #fff4c3;
}

.xs_prize4 {
  float: left;
  width: 25%;
  height: 40px;
  line-height: 40px;
  font-size: 26px;
  font-weight: bold;
}

.xs_prize4:hover {
  background-color: #fff4c3;
}

.prize-last {
  font-size: 32px;
  font-weight: bold;
  color: #ed1c25;
}

.prize_db {
  color: #ed1c25;
}

.box-loto {
  width: 100%;
  float: left;
}

.table-loto tr:nth-of-type(2n) {
  background-color: #f0f8ff;
}

.table-loto td {
  font-size: 16px;
  font-weight: bold;
}

.table-loto th {
  color: #ed1c25;
  font-weight: 400;
}

.table-loto thead th {
  background-color: #f0f8ff;
  font-size: 14px;
  color: #000;
}

.loto-name {
  width: 10%;
  text-align: center;
  color: #ed1c25;
}

.loto-number {
  width: 90%;
  font-size: 16px;
  font-weight: bold;
}

.loto-col2 {
  width: 40%;
  font-size: 16px;
  font-weight: bold;
}

.loto-col3 {
  width: 30%;
  font-size: 16px;
  font-weight: bold;
}

.loto-col4 {
  width: 22.5%;
  font-size: 16px;
  font-weight: bold;
}

@media (max-width: 960px) {
  .prize1,
  .prize2,
  .prize3,
  .prize4,
  .prize5,
  .prize6 {
    font-size: 24px;
  }

  .prize7 {
    font-size: 30px;
  }

  .xs_prize1,
  .xs_prize2,
  .xs_prize3,
  .xs_prize4 {
    font-size: 24px;
  }

  .prize-last {
    font-size: 28px;
  }

  .font4tinh {
    font-size: 22px;
  }
}

.site-link {
  width: 100%;
  float: left;
  font-size: 14px;
  font-weight: 400;
  margin-top: 5px;
  font-family: sans-serif;
}

.site-link a {
  display: initial;
  text-decoration: underline;
  font-family: sans-serif;
}

.site-link a:hover {
  color: #ed1c25;
}

.site-link a + a:before {
  padding: 0 3px;
  color: #555;
  content: "/Â ";
  display: inline-block;
}

.site-link2 {
  padding: 6px 16px;
  width: 100%;
  float: left;
  font-weight: 500;
}

.site-link2 a {
  display: contents;
}

.site-link2 a + a:before,
.site-link2 a + span:before {
  padding: 0 3px;
  color: #555;
  content: "/Â ";
}

.link-statistic {
  padding: 12px 16px;
  width: 100%;
  float: left;
}

.link-statistic li {
  float: left;
  width: 100%;
  padding: 5px 0 5px 16px;
  position: relative;
}

.link-statistic li::before {
  border-top: 4px dashed;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
  content: "";
  position: absolute;
  left: 0;
  top: 15px;
  color: #444;
  -webkit-transform: rotate(-90deg);
  -moz-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  -o-transform: rotate(-90deg);
  transform: rotate(-90deg);
}

.sms-loto {
  float: left;
  width: 100%;
  border-top: dashed 0.5px #dddfe2;
  color: #555;
  font-size: 14px;
  padding: 12px 16px;
}

.sms-loto p {
  color: #555;
}

.mega-results {
  text-align: center;
  width: 100%;
  float: left;
}

.mega-prize {
  width: 100%;
  float: left;
}

.jackpot-item {
  margin-top: 16px;
  width: 100%;
  float: left;
}

.jackpot-bonus-vnd {
  background: #ed3b3b;
  border-radius: 25px;
  color: #fff;
  font-size: 24px;
  padding: 3px 15px;
  display: inline-block;
  font-weight: bold;
}

.font-Jackpot {
  font-size: 18px;
  font-weight: 500;
}

.btn-results {
  font-size: 24px;
  font-weight: bold;
  color: #ed3b3b;
  border-radius: 100%;
  border: solid 2px #dddfe2;
  display: inline-block;
  height: 44px;
  width: 44px;
  text-align: center;
  line-height: 40px;
}

.jackpot-mega1 {
  padding-bottom: 10px;
  font-size: 18px;
}

.jackpot-text {
  font-size: 20px;
  font-weight: 500;
}

.bg_jackpot {
  background: #ffd200;
}

.cl_jackpot {
  color: #ed3b3b;
}

.table-mega {
  border-collapse: collapse;
  border-spacing: 0;
  width: 100%;
  font-size: 13px;
}

.table-mega td,
.table-mega th {
  padding: 3px 5px;
  border: solid 1px #eaeaea;
  vertical-align: middle;
}

.table-mega tr:nth-of-type(2n) {
  background-color: #f0f8ff;
}

.table-mega thead th {
  background-color: #f0f8ff;
}

.max4d-loto {
  font-size: 16px;
  font-weight: bold;
}

.table-xsdt th {
  font-weight: normal;
  font-size: 14px;
  text-align: left;
  background: #f0f8ff;
  padding: 8px;
}

.table-xsdt td {
  font-size: 22px;
  text-align: center;
  font-weight: bold;
  padding: 3px;
}

.notification {
  width: 100%;
  float: left;
  padding: 7px 10px;
  background: #ffeca0;
  text-align: center;
  color: #ed1c25;
  font-weight: 500;
  font-size: 16px;
  margin-bottom: 10px;
  -webkit-animation: notification 0.7s infinite;
  -moz-animation: notification 0.7s infinite;
  -o-animation: notification 0.7s infinite;
  animation: notification 0.7s infinite;
}

@-webkit-keyframes notification {
  50% {
    background: #f2f2f2;
  }
}

.notification2 {
  width: 100%;
  float: left;
  padding: 10px 16px;
  border-bottom: solid 1px #e0e0e0;
  font-weight: 500;
}

.lottery-today {
  text-align: center;
}

.lottery-today a {
  float: left;
  width: 100%;
  color: #0029ad;
}

.box-live {
  padding-bottom: 10px;
}

.live-lottery {
  font-family: sans-serif;
  float: left;
  width: 100%;
  margin-bottom: 16px;
  padding: 7px;
  text-align: center;
  position: relative;
  background: #ffe4c4;
}

.live-title {
  font-family: sans-serif;
  font-weight: 700;
}

.live-box-title {
  font-size: 18px;
  text-align: center;
  font-weight: bold;
}

.live-btn {
  font-family: sans-serif;
  cursor: pointer;
  background: #c80505;
  border-radius: 2px;
  color: #fff;
  padding: 2px 5px;
  font-size: 14px;
  display: inline-block;
  -webkit-animation: live-btn 0.7s infinite;
  -moz-animation: live-btn 0.7s infinite;
  -o-animation: live-btn 0.7s infinite;
  animation: live-btn 0.7s infinite;
  margin-left: 10px;
}

.live-btn:hover {
  color: #fff;
}

@-webkit-keyframes live-btn {
  50% {
    background: #4285f4;
    color: #fff;
  }
}

@media (max-width: 960px) {
  .live-lottery {
    margin-bottom: 12px;
    padding: 0;
    background: none;
  }
}

.link-loto {
  width: 100%;
  float: left;
  padding: 10px 16px;
  font-family: sans-serif;
}

.link-loto a {
  width: 50%;
  float: left;
  font-size: 14px;
  padding: 5px 0;
  position: relative;
  padding-left: 24px;
  font-family: sans-serif;
}

.link-loto a:hover {
  color: #ed1c25;
}

.link-loto a::before {
  content: "HOT";
  position: absolute;
  background: #ffeca0;
  font-size: 9px;
  padding: 0 3px;
  border-radius: 2px;
  margin-left: -30px;
  color: #ed1c25;
}

.menu-item2 {
  display: table;
  width: 100%;
  float: left;
  font-family: sans-serif;
}

.menu-item2 a {
  display: table-cell;
  width: 50%;
  float: left;
  padding: 5px 0;
  text-align: center;
  font-size: 14px;
  border-left: solid 1px #dddfe2;
  border-bottom: solid 1px #dddfe2;
  background: #f0f8ff;
  color: #222;
  font-family: sans-serif;
}

.menu-item2 a:hover {
  color: #fff;
  background: #ed1c25;
  border-left: solid 1px #ed1c25;
  border-bottom: solid 1px #ed1c25;
}

.menu-item2 a.active {
  color: #fff;
  background: #ed1c25;
  border-left: solid 1px #ed1c25;
  border-bottom: solid 1px #ed1c25;
}

.menu-item3 {
  display: table;
  width: 100%;
  float: left;
  font-family: sans-serif;
}

.menu-item3 a {
  display: table-cell;
  width: 33.33%;
  float: left;
  padding: 5px 0;
  text-align: center;
  font-size: 14px;
  border-left: solid 1px #dddfe2;
  border-bottom: solid 1px #dddfe2;
  background: #f0f8ff;
  color: #222;
  font-family: sans-serif;
}

.menu-item3 a:hover {
  color: #fff;
  background: #ed1c25;
  border-left: solid 1px #ed1c25;
  border-bottom: solid 1px #ed1c25;
}

.menu-item3 a.active {
  color: #fff;
  background: #ed1c25;
  border-left: solid 1px #ed1c25;
  border-bottom: solid 1px #ed1c25;
}

.menu-item4 {
  display: table;
  width: 100%;
  float: left;
  font-family: sans-serif;
}

.menu-item4 a {
  display: table-cell;
  width: 25%;
  float: left;
  padding: 5px 0;
  text-align: center;
  font-size: 14px;
  border: solid 1px #dddfe2;
  border-left: 0;
  border-bottom: 0;
  color: #222;
  font-family: sans-serif;
}

.menu-item4 a:nth-child(4n + 4) {
  border-right: 0;
}

.menu-item4 a:hover {
  color: #fff;
  background: #ed1c25;
  border: solid 1px #ed1c25;
  border-left: 0;
  border-bottom: 0;
}

.menu-item4 a.active {
  color: #fff;
  background: #ed1c25;
  border: solid 1px #ed1c25;
  border-left: 0;
  border-bottom: 0;
}

.menu-item5 {
  display: table;
  width: 100%;
  float: left;
}

.menu-item5 a {
  display: table-cell;
  width: 20%;
  float: left;
  padding: 10px 0;
  text-align: center;
  font-size: 14px;
  border: solid 1px #dddfe2;
  border-left: 0;
  border-bottom: 0;
  color: #222;
}

.menu-item5 a:nth-child(5n + 5) {
  border-right: 0;
}

.menu-item5 a:hover {
  color: #fff;
  background: #ed1c25;
  border: solid 1px #ed1c25;
  border-left: 0;
  border-bottom: 0;
}

.menu-item5 a.active {
  color: #fff;
  background: #ed1c25;
  border: solid 1px #ed1c25;
  border-left: 0;
  border-bottom: 0;
}

@media (max-width: 400px) {
  .menu-item5 a {
    font-size: 12px;
  }
}

@media screen and (min-width: 320px) and (max-width: 600px) {
  .mobi-item3 {
    float: left;
    width: 32% !important;
    margin-right: 2% !important;
    position: relative;
  }

  .mobi-item3:nth-child(3n + 3) {
    margin-right: 0 !important;
  }
}

.textbold {
  font-weight: bold;
}

.tai-app {
  margin-bottom: 16px;
  width: 100%;
  float: left;
  background: #ffeca0;
  padding: 12px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  -webkit-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16),
    0 2px 10px 0 rgba(0, 0, 0, 0.12);
  -moz-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16),
    0 2px 10px 0 rgba(0, 0, 0, 0.12);
}

.tai-app-a {
  font-weight: bold;
  color: #ed1c25;
}

.lottery-info {
  padding: 16px;
  font-size: 15px;
}

.lottery-info h3 {
  padding-bottom: 10px;
}

.lottery-info p {
  padding-bottom: 10px;
  color: #222;
}

.block-heading2 {
  float: left;
  width: 100%;
  position: relative;
  background: #f0f8ff;
  border: solid 1px #dddfe2;
  padding: 8px 10px;
}

.block-heading2 h2 {
  font-size: 16px;
  font-weight: bold;
}

.block-heading2 a {
  color: #ed1c25;
}

.statistic-link {
  padding: 10px;
  width: 100%;
  font-size: 14px;
  float: left;
  text-align: center;
  background: #ffeca0;
}

.statistic-link a {
  color: #0029ad;
}

.statistic-link a:hover {
  color: #ed1c25;
}

.statistic-link a + a::before {
  padding: 0 5px;
  color: #555;
  content: "|";
}

.statistic-link2 {
  float: left;
  width: 100%;
  padding: 16px 16px 0 16px;
}

.statistic-link2 h3 {
  float: left;
  width: 100%;
  font-size: 16px;
  padding-bottom: 5px;
}

.statistic-link2 a {
  float: left;
  width: 100%;
  padding: 5px 0 5px 16px;
  position: relative;
}

.statistic-link2 a::before {
  border-top: 4px dashed;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
  content: "";
  position: absolute;
  left: 0;
  top: 15px;
  color: #444;
  -webkit-transform: rotate(-90deg);
  -moz-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  -o-transform: rotate(-90deg);
  transform: rotate(-90deg);
}

.loto-gan {
  float: left;
  width: 100%;
  padding: 12px;
}

.logan-title {
  padding-bottom: 5px;
}

.live-reporting {
  padding: 16px;
  float: left;
  width: 100%;
}

.count_seconds {
  background: #c80505;
  color: #fff;
  padding: 0 10px;
  display: inline-block;
  margin: 0 5px;
  border-radius: 2px;
  font-size: 20px;
  height: 36px;
  line-height: 36px;
  font-weight: bold;
}

.icontime {
  font-size: 14px;
  font-weight: normal;
}

.text-seconds {
  display: inline-block;
  margin-right: 5px;
  line-height: 36px;
}

.box-seconds {
  width: 100%;
  float: left;
  margin-bottom: 16px;
}

.box-info {
  width: 100%;
  float: left;
  font-size: 14px;
}

.box-info p {
  color: #333;
  padding-top: 8px;
}

.box-info p:first-child {
  padding-top: 0;
}

.link-xs {
  font-size: 16px;
  font-weight: bold;
}

.page-title {
  font-size: 18px;
  padding: 12px 16px;
}

.mega-results.p10 {
  padding: 0 10px 10px 10px;
}

.pad16 {
  padding: 16px;
}

.list-statistic {
  padding: 8px 16px;
  float: left;
  width: 100%;
  position: relative;
}


.list-statistic .form-group
{
display: flex;
align-items: center;
}


.list-statistic p {
  padding: 5px 0;
}

.number-tk span {
  display: inline-block;
  background: #edf2fa;
  padding: 5px 16px;
  margin: 5px 5px 5px 0;
  border-radius: 2px;
  font-size: 16px;
  font-weight: bold;
  cursor: pointer;
}

.number-tk span:hover {
  background: #ed1c25;
  color: #fff;
}

.number-tk span.active {
  background: #ed1c25;
  color: #fff;
}

.caumbresult {
  font-size: 20px;
  font-weight: bold;
  color: #ffa500;
}

.caumbchon {
  font-size: 20px;
  font-weight: bold;
  color: #0029ad;
}

.cau-xs {
  cursor: pointer;
}

.cau-selected {
  color: #00f;
  font-size: 32px;
}

.cau-result {
  color: #ffa500;
  font-size: 32px;
}

.link-tk {
  width: 100%;
  float: left;
}

.link-tk li {
  float: left;
  width: 100%;
  padding: 5px 0 5px 16px;
  position: relative;
}

.link-tk li::before {
  border-top: 4px dashed;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
  content: "";
  position: absolute;
  left: 0;
  top: 15px;
  color: #444;
  -webkit-transform: rotate(-90deg);
  -moz-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  -o-transform: rotate(-90deg);
  transform: rotate(-90deg);
}

.item-group {
  float: left;
  width: 31.3%;
  margin-right: 3%;
  position: relative;
}

.label-text {
  padding-bottom: 5px;
  float: left;
  width: 100%;
  font-size: 14px;
}

.item-btn {
  margin-top: 27px;
  float: left;
}

.title-cau {
  padding-bottom: 10px;
}

.w25 {
  width: 25%;
}

.wc4 {
  width: 25%;
}

.wc3 {
  width: 33.33%;
}

.wc2 {
  width: 50%;
}

.wc1 {
  width: 20%;
}

.number-DB {
  font-size: 16px;
  font-weight: bold;
}

.text-date {
  font-size: 11px;
  color: #555;
}

.table-scrol-x {
  overflow-x: auto;
  white-space: nowrap;
  float: left;
  width: 100%;
}

.bge1f0db {
  background: #e1f0db;
}

.title-table {
  float: left;
  width: 100%;
  background: #565656;
  padding: 6px 16px;
  text-align: center;
  font-size: 16px;
  font-weight: bold;
  color: #fff;
}

.ic_ca {
  background: url(./images/Xoso\ Quay\ Thu.png) no-repeat;
  background-position-x: 0%;
  background-position-y: 0%;
  background-position: right 8px top 50%;
  padding-right: 24px;
}

.btn-item2 {
  float: left;
  width: 110px;
  margin-right: 10px;
}

.btn-item3 {
  float: left;
  margin-top: 27px;
}

.btn-item1 {
  float: left;
  width: 150px;
  margin-right: 10px;
}

.table-tk {
  font-size: 14px;
}

.table-tk input {
  border: none;
  height: 10px;
  float: none;
  background-color: #505edc;
}

.table-tk tbody th {
  color: #ed1c25;
  font-weight: 400;
}

.th-loxien {
  width: 25%;
}

.table-scroll-fixed {
  position: relative;
  width: 100%;
  z-index: 1;
  overflow: scroll;
  max-height: 300px;
}

.table-scroll-fixed650 {
  position: relative;
  width: 100%;
  z-index: 1;
  overflow: scroll;
  max-height: 650px;
}

.table-scroll-fixed1 {
  position: relative;
  width: 100%;
  z-index: 1;
  overflow: scroll;
  max-height: auto;
}

.table-fixed {
  border-collapse: separate;
  text-align: center;
}

.table-fixed th,
.table-fixed td {
  padding: 5px;
  border-right: 0;
  border-bottom: 0;
}

.table-fixed thead th {
  background: #dbebf8;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}

.table-tk-gdbnam th {
  min-width: 65px;
}

.table-fixed th:first-child {
  position: -webkit-sticky;
  position: sticky;
  left: 0;
  z-index: 2;
  background: #dbebf8;
}

.table-fixed thead th:first-child {
  z-index: 5;
}

.bgfdcfcf {
  background: #fdcfcf;
}

.title-item {
  font-size: 16px;
  padding: 0 16px;
}

.category-title {
  font-size: 18px;
  font-weight: bold;
}

.number-spin {
  text-align: center;
  width: 100%;
  float: left;
  padding: 10px;
}

.labelspin {
  float: none;
  display: inline-block;
  margin: 0 10px;
}

.pr7 {
  font-size: 16px;
  font-weight: bold;
}

.prdb {
  font-size: 18px;
  font-weight: bold;
}

.bgtr {
  background: #f0f8ff;
}

.item_xs1 {
  float: left;
  width: 100%;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: 5px;
}

.item_xs2 {
  float: left;
  width: 50%;
  border-left: solid 1px #dddfe2;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: 5px;
}

.item_xs2:first-child {
  border-left: 0;
}

.item_xs3 {
  float: left;
  width: 33.33%;
  border-left: solid 1px #dddfe2;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: 5px;
}

.item_xs3:first-child {
  border-left: 0;
}

.item_xs4 {
  float: left;
  width: 25%;
  border-left: solid 1px #dddfe2;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
  padding: 5px;
}

.item_xs4:first-child {
  border-left: 0;
}

.xs_font {
  font-size: 18px;
  font-weight: bold;
}

.colorred {
  color: #ed1c25;
}

.table-result.result-xs {
  font-size: 13px;
  text-align: center;
}

.thbs {
  min-width: 50px;
}

@media (max-width: 960px) {
  .btn-item {
    margin-bottom: 5px;
    margin-top: 5px;
  }
}

#ajaxContentContainer {
  float: left;
  width: 100%;
  margin-bottom: 12px;
  position: relative;
  background: #fff;
}

.red {
  color: #ed1c25;
}

.title-post {
  font-size: 18px;
  margin: 0;
  padding: 50px 20px;
}

#hover-number td:hover {
  background-color: #00918d;
}

.runloto-0,
.runloto-1,
.runloto-2,
.runloto-3,
.runloto-4,
.runloto-5,
.runloto-6,
.runloto-7,
.runloto-8,
.runloto-9 {
  border-radius: 100%;
  color: #fff;
  padding: 0 3px;
  font-size: 22px;
}

.runloto-0,
.runloto-2,
.runloto-4,
.runloto-6,
.runloto-8 {
  background: #333;
  background: -moz-linear-gradient(-45deg, rgba(235, 113, 100, 1) 0, #000);
  background: -webkit-gradient(
    left top,
    right bottom,
    color-stop(0, #333),
    color-stop(100%, #333)
  );
  background: -webkit-linear-gradient(-45deg, #333 0, #333 100%);
  background: -o-linear-gradient(-45deg, #333 0, #333 100%);
  background: -ms-linear-gradient(-45deg, #333 0, #333 100%);
  background: linear-gradient(135deg, #333 0, #333 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#333',endColorstr='#333',GradientType=1);
}

.runloto-1,
.runloto-3,
.runloto-5,
.runloto-7,
.runloto-9 {
  background: #b01014;
  background: -moz-linear-gradient(-45deg, #b01014 0, #b01014 100%);
  background: -webkit-gradient(
    left top,
    right bottom,
    color-stop(0, #b01014),
    color-stop(100%, #b01014)
  );
  background: -webkit-linear-gradient(-45deg, #b01014 0, #b01014 100%);
  background: -o-linear-gradient(-45deg, #b01014 0, #b01014 100%);
  background: -ms-linear-gradient(-45deg, #b01014 0, #b01014 100%);
  background: linear-gradient(135deg, #b01014 0, #b01014 100%);
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#b01014',endColorstr='#b01014',GradientType=1);
}

.sms-loto a {
  text-decoration: underline;
}

.btn-item2.fixw {
  max-width: 130px;
}

.bgAliceblue {
  background-color: #f0f8ff;
}

.ac_title_2.active {
  color: #ed1c25;
}

.ac_ul2 a.active {
  background: #f0f8ff;
}

#thongKe0145Kq input {
  padding: 0;
}

#thongKeMegaNhieuItKq input {
  padding: 0;
}

.btn-danger2 {
  background-color: #ed1c25;
  height: 40px;
  line-height: 40px;
  color: #fff;
}

.btn-danger3 {
  background-color: #ed1c25;
  height: 40px;
  line-height: 40px;
  color: #fff;
}

@media only screen and (min-width: 780px) {
  .advstickyleft {
    position: fixed;
    bottom: 8px;
    right: calc(50% + 518px);
    max-width: 200px;
    z-index: 1000;
  }

  .advstickyright {
    position: fixed;
    bottom: 8px;
    left: calc(50% + 518px);
    max-width: 200px;
    z-index: 1000;
  }

  .advfixfooter {
    display: none;
  }

  .advrightfooter {
    max-width: 500px;
    position: fixed;
    right: 0;
    bottom: 0;
  }
}

@media only screen and (max-width: 779px) {
  .advstickyleft {
    display: none;
  }

  .advstickyright {
    display: none;
  }

  .advfixfooter {
    position: fixed;
    max-height: 120px;
    width: 100%;
    z-index: 1000;
    left: 0;
    bottom: 0;
    text-align: center;
    clear: both;
    background: #fff;
  }

  .advfixfooter .ads {
    padding: 0 !important;
    margin: 0 !important;
  }

  .ads {
    padding: 30px 0;
    clear: both;
    text-align: center;
  }

  .advfixfooter:empty {
    display: none;
  }

  .advfixfooterClose {
    position: absolute;
    width: 30px;
    height: 30px;
    top: -30px;
    right: 0;
    padding: 3px;
    z-index: 9999;
    cursor: pointer;
    background-size: 13px 13px;
    background-position: 9px;
    background-color: #fff;
    background-repeat: no-repeat;
    box-shadow: 0 -1px 1px 0 rgba(0, 0, 0, 0.2);
    border: none;
    border-radius: 12px 0 0 0;
  }

  .advrightfooter {
    display: none;
  }
}

.the-article {
  float: left;
  width: 100%;
  position: relative;
  padding: 16px;
  margin-bottom: 16px;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  -webkit-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16),
    0 2px 10px 0 rgba(0, 0, 0, 0.12);
  -moz-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16),
    0 2px 10px 0 rgba(0, 0, 0, 0.12);
}

.the-article-header {
  width: 100%;
  float: left;
  font-size: 24px;
  padding-bottom: 10px;
}

.the-article-title {
  width: 100%;
  float: left;
  font-size: 24px;
  padding-bottom: 5px;
}

.the-article-time {
  width: 100%;
  float: left;
  padding-bottom: 5px;
  font-size: 12px;
  color: #666;
}

.the-article-catalogy {
  width: 100%;
  float: left;
  padding-bottom: 5px;
  font-size: 14px;
}

.the-article-catalogy a {
  border: solid 1px #e0e0e0;
  margin: 0 10px 5px 0;
  padding: 3px 10px;
  float: left;
  border-radius: 2px;
  font-size: 13px;
}

.the-article-share {
  width: 100%;
  float: left;
  font-size: 14px;
}

.the-article-content {
  font-size: 16px;
  padding-bottom: 16px;
  float: left;
  width: 100%;
}

.the-article-content h2,
.the-article-content h3,
.the-article-content h4 {
  padding: 5px 0;
  font-size: 18px;
}

.the-article-content p {
  padding: 5px 0;
  color: #222;
}

.the-article-content a {
  color: #4285f4;
}

.the-article-dropcap {
  display: block;
  float: left;
  margin: 0 8px 0 0;
  font-size: 50px;
  line-height: 50px;
}

.the-article-image {
  padding: 8px 0;
  text-align: center;
  position: relative;
}

.the-article-image img {
  max-width: 640px;
  width: 100%;
}

.caption-image {
  font-size: 14px;
  color: #555;
  padding: 0 10px;
  font-style: italic;
}

.the-block-quote {
  color: #666;
  font-style: italic;
  margin: 16px 0;
  padding: 0 16px;
  width: 100%;
  font-size: 16px;
  padding-left: 10%;
  position: relative;
}

.the-block-quote::before {
  content: "";
  position: absolute;
  height: 100%;
  width: 3px;
  background: #999;
  margin-left: -16px;
}

.the-article-tags {
  float: left;
  width: 100%;
  position: relative;
  margin-bottom: 16px;
}

.the-article-tags a {
  float: left;
  border: solid 1px #e0e0e0;
  margin-right: 5px;
  font-size: 13px;
  padding: 3px 10px;
  border-radius: 2px;
}

.the-article-tags span {
  float: left;
  margin-right: 10px;
  line-height: 28px;
  font-size: 14px;
}

.the-article-link {
  border: solid 1px #e0e0e0;
  padding: 10px 15px;
  margin: 10px 0;
}

.the-article-link > li {
  padding: 6px 0;
  padding-left: 16px;
  position: relative;
}

.the-article-link > li::before {
  content: "â€¢";
  display: inline-block;
  position: absolute;
  left: 0;
  color: #333;
  font-size: 18px;
}

.the-article-link > li > a {
  display: grid;
  font-size: 16px;
}

.the-article-box {
  margin: 16px 0;
  width: 100%;
  float: left;
  font-size: 15px;
}

.the-article-list {
  float: left;
  width: 100%;
  padding: 12px;
  border: solid 1px #e0e0e0;
  position: relative;
  margin-top: -1px;
}

.the-article-author {
  float: left;
  width: 100%;
  position: relative;
  text-align: right;
  font-size: 16px;
  font-weight: 500;
}

.the-article-label {
  display: inline-block;
  padding: 0 5px;
  background: #4285f4;
  line-height: 1.5;
  font-size: 14px;
  color: #fff;
  vertical-align: middle;
  white-space: nowrap;
  border-radius: 2px;
}

.ic-circle-item {
  float: left;
  font-size: 6px;
  margin-right: 8px;
  margin-top: 10px;
}

.section-related {
  padding: 16px 0;
  float: left;
  width: 100%;
  border-top: solid 1px #dddfe2;
}

.related-header {
  float: left;
  width: 100%;
  padding-bottom: 16px;
}

.related-links li {
  padding: 7px 0;
  padding-left: 15px;
  position: relative;
  font-size: 16px;
}

.related-links li::before {
  position: absolute;
  content: "";
  height: 5px;
  width: 5px;
  background: #4285f4;
  left: 0;
  top: 17px;
  border-radius: 100%;
}

.related-title {
  float: left;
  width: 100%;
}

.section-comment {
  padding: 16px 0;
  padding-bottom: 0;
  float: left;
  width: 100%;
  border-top: solid 1px #dddfe2;
}

@media only screen and (max-width: 960px) {
  .the-article {
    margin-bottom: 12px;
  }

  .the-article-content {
    font-size: 18px;
  }

  .the-article-content h2,
  .entry-content h3,
  .entry-content h4 {
    font-size: 20px;
  }

  .the-article-tags a {
    margin-bottom: 5px;
  }
}

.post-social {
  padding: 10px 0;
  float: left;
  width: 100%;
}

.icon-social {
  background-color: #f2f2f2;
  float: left;
  min-width: 36px;
  height: 36px;
  line-height: 36px;
  padding: 0 10px;
  text-align: center;
  margin-right: 5px;
  margin-bottom: 5px;
  border-radius: 2px;
  cursor: pointer;
}

.icon-social:last-child {
  margin-right: 0;
}

.icon-social:hover {
  color: #fff;
  opacity: 0.9;
}

.facebook {
  background: #3b5998;
  color: #fff;
}

.twitter {
  background: #55acee;
  color: #fff;
}

.google {
  background: #dd4b39;
  color: #fff;
}

.linkedin {
  background: #007bb5;
  color: #fff;
}

.youtube {
  background: #b00;
  color: #fff;
}

.instagram {
  background: #125688;
  color: #fff;
}

.pinterest {
  background: #cb2027;
  color: #fff;
}

.snapchat {
  background: #fffc00;
  color: #fff;
  text-shadow: -1px 0 black, 0 1px black, 1px 0 black, 0 -1px black;
}

.skype {
  background: #00aff0;
  color: #fff;
}

.android {
  background: #a4c639;
  color: #fff;
}

.dribbble {
  background: #ea4c89;
  color: #fff;
}

.vimeo {
  background: #45bbff;
  color: #fff;
}

.tumblr {
  background: #2c4762;
  color: #fff;
}

.vine {
  background: #00b489;
  color: #fff;
}

.foursquare {
  background: #45bbff;
  color: #fff;
}

.stumbleupon {
  background: #eb4924;
  color: #fff;
}

.flickr {
  background: #f40083;
  color: #fff;
}

.yahoo {
  background: #430297;
  color: #fff;
}

.soundcloud {
  background: #f50;
  color: #fff;
}

.reddit {
  background: #ff5700;
  color: #fff;
}

.rss {
  background: #f60;
  color: #fff;
}

.comment-title {
  padding-bottom: 20px;
  font-size: 18px;
}

.box-comment {
  float: left;
  width: 100%;
}

.item-comment {
  float: left;
  width: 100%;
  position: relative;
  border-top: solid 1px #e0e0e0;
  padding: 16px 0;
}

.item-comment:first-child {
  border-top: 0;
}

.avata-comment {
  float: left;
  margin-right: 16px;
}

.img-comment {
  height: 48px;
  width: 48px;
  border-radius: 100%;
  float: left;
}

.post-comment {
  display: grid;
}

.comment-content2 {
  margin-top: 16px;
  padding-top: 16px;
}

.comment-user {
  font-weight: 500;
}

.comment-entry {
  padding-bottom: 5px;
}

.comment-tags {
  float: left;
  font-size: 13px;
  margin-right: 16px;
  color: #666;
}

.comment-tags.active {
  color: #4285f4;
}

.textarea-comment {
  float: left;
  width: 100%;
  margin-bottom: 16px;
}

.btn-comment {
  float: left;
  width: 100%;
}

.btn-right {
  float: right;
}

.comment-time {
  font-size: 13px;
  font-weight: normal;
  color: #666;
}

.comment-text {
  width: 100%;
  float: left;
  position: relative;
  margin-top: 16px;
}

.textarea-48 {
  height: 48px;
  padding-right: 48px;
}

.btn-textarea {
  position: absolute;
  right: 0;
  height: 48px;
  width: 48px;
  line-height: 48px;
  cursor: pointer;
  text-align: center;
  font-size: 20px;
  color: #4285f4;
}

.post-reply {
  position: relative;
  float: left;
  width: 100%;
  margin-top: 10px;
}

@media only screen and (max-width: 800px) {
  .div-col {
    padding: 0 6px;
  }
}

.the-article-content ul {
  margin: 12px 0;
  padding-left: 16px;
}

.the-article-content li {
  list-style: disc;
  padding: 5px 0;
}

::marker {
  unicode-bidi: isolate;
  font-variant-numeric: tabular-nums;
  text-transform: none;
  text-indent: 0 !important;
  text-align: start !important;
  text-align-last: start !important;
}

.code-DB1,
.code-DB10,
.code-DB11,
.code-DB12,
.code-DB13,
.code-DB14,
.code-DB15,
.code-DB2,
.code-DB3,
.code-DB4,
.code-DB5,
.code-DB6,
.code-DB7,
.code-DB8,
.code-DB9 {
  float: left;
  height: 40px;
  line-height: 40px;
  color: #ed1c25;
  font-weight: 700;
}

.code-DB10,
.code-DB11,
.code-DB12,
.code-DB13,
.code-DB14,
.code-DB15,
.code-DB16,
.code-DB17,
.code-DB18,
.code-DB19,
.code-DB20 {
  float: left;
  margin: 0 6px;
  color: #ed1c25;
  font-weight: 700;
}

.code-DB1 {
  width: 100%;
  font-size: 22px;
}

.code-DB2 {
  width: 50%;
  font-size: 22px;
}

.code-DB3 {
  width: 33.3%;
  font-size: 22px;
}

.code-DB4 {
  width: 25%;
  font-size: 22px;
}

.code-DB5 {
  width: 20%;
  font-size: 22px;
}

.code-DB6 {
  width: 16.5%;
  font-size: 20px;
}

.code-DB7 {
  width: 14%;
  font-size: 20px;
}

.code-DB8 {
  width: 12.5%;
  font-size: 20px;
}

.code-DB9 {
  width: 11%;
  font-size: 20px;
}

.code-DB10 {
  width: 20%;
  font-size: 20px;
}

.tk-h2 {
  font-size: 20px;
  font-weight: bold;
  margin-bottom: 16px;
}

.tk-h3 {
  font-size: 18px;
  margin-bottom: 16px;
  font-weight: bold;
}

.table.table-col3 {
  width: 32%;
  float: left;
  margin-right: 2%;
}

.table.table-col3:last-child {
  margin-right: 0;
}

.table.table-col2 {
  width: 48%;
  float: left;
  margin-right: 2%;
}

.table.table-col2:last-child {
  margin-right: 0;
}

.lo-number {
  font-size: 18px;
  font-weight: bold;
}

.lo-text {
  font-size: 16px;
}

.font-th {
  color: #c80505;
  font-weight: bold;
  font-size: 16px;
}

.table-striped tr:nth-of-type(2n + 2) {
  background-color: #f9f9f9;
}

.textupcase {
  font-size: 15px;
  font-weight: bold;
}

hr {
  height: 1px;
  border: 0;
  border-bottom: solid 1px #e0e0e0;
}

.btn-item-outline {
  display: inline-block;
  border: solid 1px #e0e0e0;
  height: 40px;
  line-height: 40px;
  padding: 0 8px;
  border-radius: 4px;
  cursor: pointer;
  margin-right: 5px;
  margin-bottom: 5px;
}

.btn-item-outline:hover {
  border: solid 1px #0029ad;
  color: #0029ad;
}

.btn-item-outline.active {
  border: solid 1px #0029ad;
  color: #0029ad;
}

.row-item {
  width: 100%;
  float: left;
}

.div-row {
  margin-bottom: 16px;
  width: 100%;
  float: left;
  font-size: 16px;
}

.table-chuky {
  background: #9bd1ff;
  color: #fff;
}

.bg-chuky1 {
  background: #4cafff;
}

.bg-chuky2 {
  background: #0288f7;
}

.bg-chuky3 {
  background: #004cff;
}

.bg-chuky4 {
  background: #144cce;
}

.bgitnhieu {
  background: #9bd1ff;
  background: linear-gradient(
    107deg,
    rgba(155, 209, 255, 1) 0%,
    rgba(0, 76, 255, 1) 72%
  );
  width: 100px;
  display: inline-block;
  height: 12px;
  border-radius: 24px;
}

.div-item-tk {
  float: left;
  margin-right: 16px;
}

.imglnh {
  float: left;
  height: 24px;
  margin-right: 6px;
}

.div-row-bg {
  background: #f2f2f2;
  padding: 8px;
  float: left;
  width: 100%;
}

.table-h td {
  height: 32px;
}

.table.table-h {
  vertical-align: middle;
}

.table-h img {
  height: 26px;
  display: inline-block;
  width: 26px;
}

.imgdauso {
  float: left;
  margin-right: 6px;
}

.spandauso {
  font-size: 18px;
}

.tbgred {
  background: #c80000;
  color: #fff;
}

.mucluc-tk {
  float: left;
  width: 100%;
  margin-bottom: 16px;
}

.mucluc-content {
  float: left;
  width: 280px;
  background: #f2f2f2;
  padding: 10px;
  font-size: 16px;
}

.ul-muluc {
  margin: 0;
  padding: 0;
}

.mucluc-head {
  font-weight: bold;
  padding-bottom: 5px;
}

.ul-muluc li {
  padding: 2px 0;
  color: #222;
}

.ul-muluc a {
  color: #222;
}

.section-content.pad16px {
  padding: 16px;
}

.section-header .site-link {
  font-size: 14px;
  font-weight: 400;
  margin-top: 5px;
}

.keno-row {
  float: left;
  width: 100%;
  margin-bottom: 16px;
}

.keno-bg {
  float: left;
  width: 100%;
  background: #ffeca0;
  padding: 8px;
}

.xs-col2 {
  float: left;
  width: 48.5%;
  margin-right: 3%;
  position: relative;
}

.xs-col2:last-child {
  margin-right: 0;
}

.row-cl {
  background: #fff;
  display: block;
  line-height: 24px;
  margin: 2px 0;
  font-weight: bold;
  border-radius: 3px;
  border: solid 1px #c80505;
  padding: 4px 6px;
}

.ic-cl {
  display: inline-block;
  background: #666;
  color: #fff;
  width: 24px;
  height: 24px;
  text-align: center;
  line-height: 24px;
  border-radius: 100%;
  margin-right: 6px;
}

.bg-chan {
  background: #1b66b9;
}

.bg-e76801 {
  background: #e76801;
}

.bg-le {
  background: #45ace7;
}

.bg-be {
  background: #e99a19;
}

.bg-lon {
  background: #f55716;
}

.number-cl {
  float: right;
}

.ic-cl img {
  height: 14px;
  width: 14px;
  position: relative;
  top: 2px;
}

.btn-number-live {
  background: #fff;
  height: 44px;
  line-height: 44px;
  width: 44px;
  text-align: center;
  border-radius: 100%;
  border: solid 1px #c80505;
  color: #c80505;
  font-size: 24px;
  font-weight: bold;
  display: inline-block;
}

.btn-number-live:last-child {
  margin-right: 0;
}

.kenno-btn {
  text-align: center;
}

.btn-number-live img {
  height: 14px;
}

.keno-content {
  width: 100%;
  float: left;
}

.keno-col1 {
  float: left;
  width: 18%;
}

.keno-col2 {
  float: left;
  width: 52%;
}

.keno-col3 {
  float: left;
  width: 30%;
}

.span-row {
  display: block;
}

.btn-number-kq {
  background: #fff;
  height: 32px;
  line-height: 32px;
  width: 32px;
  text-align: center;
  border-radius: 6px;
  border: solid 1px #c80505;
  font-size: 16px;
  font-weight: bold;
  display: inline-block;
}

.keno-box1 {
  width: 30%;
  float: left;
}

.keno-box2 {
  width: 70%;
  float: right;
  padding: 10px;
}

.layout-grid-10 {
  display: grid;
  grid-column-gap: 5px;
  grid-row-gap: 5px;
  grid-template-columns: repeat(10, 1fr);
  place-items: center;
}

.kenno-btn-kq {
  padding: 0 20px;
  display: grid;
  grid-column-gap: 5px;
  grid-row-gap: 5px;
  grid-template-columns: repeat(10, 1fr);
  place-items: center;
}

.item-c-l {
  display: inline-block;
  border: solid 1px #e0e0e0;
  background: #f2f2f2;
  width: 100px;
  border-radius: 3px;
  margin-bottom: 3px;
  text-align: center;
  height: 32px;
  line-height: 32px;
  font-weight: bold;
}

.keno-row-c-l {
  text-align: center;
  margin-bottom: 5px;
}

.keno-row-c-l:last-child {
  margin-bottom: 0;
}

.item-c-l.bg-active {
  background: #c80505;
  color: #fff;
  border: solid 1px #c80505;
}

.keno-row1 {
  float: left;
  width: 100%;
  padding: 16px 0;
  border-bottom: solid 1px #eaeaea;
}

.keno-ketqua {
  width: 100%;
  float: left;
  margin-bottom: 12px;
}

.link-statistic.padding0 {
  padding: 0;
}

.headding-title {
  width: 100%;
  font-weight: 700;
  float: left;
  font-size: 22px;
  margin-bottom: 12px;
}

.keno-block {
  width: 100%;
  float: left;
  margin-bottom: 24px;
}

.keno-block:last-child {
  margin-bottom: 0;
}

.tab-tkct {
  width: 100%;
  float: left;
}

.tab-tkct a {
  float: left;
  height: 32px;
  line-height: 32px;
  background: #f2f2f2;
  color: #222;
  padding: 0 10px;
  border-radius: 3px;
  margin-right: 5px;
  margin-bottom: 5px;
}

.tab-tkct a.active {
  background: #c80505;
  color: #fff;
}

.box-grid10 {
  width: 100%;
  float: left;
  display: grid;
  grid-column-gap: 5px;
  grid-row-gap: 5px;
  grid-template-columns: repeat(10, 1fr);
  place-items: center;
  padding: 6px;
}

.bang-tkkn {
  width: 100%;
  float: left;
  border: solid 1px #eaeaea;
}

.item-tkk {
  text-align: center;
  float: left;
}

.btn-number-tk {
  border: solid 1px #e0e0e0;
  height: 31px;
  line-height: 31px;
  width: 31px;
  text-align: center;
  border-radius: 100%;
  font-size: 16px;
  font-weight: bold;
  display: inline-block;
  background: #ffeca0;
}

.ttkk {
  width: 100%;
  float: left;
  border-bottom: solid 1px #eaeaea;
  padding: 6px;
  text-align: center;
  font-size: 16px;
  font-weight: bold;
}

.text-lan {
  font-size: 12px;
  padding-top: 5px;
}

.btn-xemchitiet {
  float: right;
  background: #ffbf3d;
  padding: 4px 16px;
  color: #fff;
  display: inline-block;
  border-radius: 25px;
}

.see-more2 {
  float: left;
  width: 100%;
}

.lottery-info {
  width: 100%;
  float: left;
}

.content-left-page {
  width: 71%;
  float: left;
}

.live-keno {
  width: 100%;
  float: left;
  margin-bottom: 16px;
}

.btn-live-time {
  background: #cf0034;
  color: #fff;
  font-size: 18px;
  padding: 2px 10px;
  border-radius: 5px;
  margin: 0 5px;
}

.mgin0 {
  margin: 0;
}

.btn-xemchitiet img {
  height: 14px;
  margin-right: 6px;
}

.live-header {
  width: 100%;
  float: left;
}

.btn-button {
  display: inline-block;
  background: #cf0034;
  padding: 4px 16px;
  border: solid 1px #cf0034;
  color: #fff;
  border-radius: 25px;
}

.btn-button:hover {
  background: #cf0034;
  color: #fff;
}

.btn-button img {
  height: 14px;
  margin-right: 6px;
}

.table24 td {
  min-width: 30px;
  min-height: 30px;
}

.keno_tructiep {
  background: #fff;
  font-size: 14px;
  font-weight: bold;
}

.keno_tructiep tbody tr:nth-child(1),
.keno_tructiep tbody tr:nth-child(2),
.keno_tructiep tbody tr:nth-child(5),
.keno_tructiep tbody tr:nth-child(6),
.keno_tructiep tbody tr:nth-child(9),
.keno_tructiep tbody tr:nth-child(10) {
  background: #fff9df;
}

.text-info {
  width: 100%;
  float: left;
  margin-bottom: 16px;
  border-bottom: solid 1px #eaeaea;
  padding-bottom: 16px;
}

.row-live-time {
  width: 100%;
  float: left;
  margin-bottom: 16px;
}

.live-font18 {
  font-size: 20px;
}

.ic-btnkeno {
  display: inline-block;
  background: #666;
  color: #fff;
  width: 24px;
  height: 24px;
  text-align: center;
  line-height: 24px;
  border-radius: 100%;
}

.contentTabKeno {
  border: solid 1px #e0e0e0;
  width: 100%;
  overflow-x: scroll;
  overflow-y: hidden;
  display: flex;
}

.colKeno {
  float: left;
}

.cellKeno {
  width: 32px;
  height: 32px;
  line-height: 32px;
  border: solid 1px #e0e0e0;
  border-left: 0;
  border-top: 0;
  text-align: center;
  background: #fff;
}

.ic-btnkeno.keno-chan {
  background: #1b66b9;
}

.ic-btnkeno.keno-le {
  background: #45ace7;
}

.ic-btnkeno-lonbe {
  display: inline-block;
  background: #666;
  color: #fff;
  width: 24px;
  height: 24px;
  text-align: center;
  line-height: 24px;
  border-radius: 100%;
}

.ic-btnkeno-lonbe img {
  height: 14px;
  width: 14px;
  position: relative;
  top: 2px;
}

.ic-btnkeno-lonbe.bgkeno-lon {
  background: #f55716;
}

.ic-btnkeno-lonbe.bgkeno-be {
  background: #e99a19;
}

.bg666 {
  background: #666;
  color: #fff;
}

.table-keno-row {
  padding: 5px;
  width: 100%;
  float: left;
}

.flex-row {
  display: flex;
  justify-content: space-around;
  margin-bottom: 5px;
  width: 100%;
  float: left;
}

.item-tkk {
  border-radius: 10px;
  width: 38px;
  text-align: center;
  vertical-align: middle;
  font-weight: 700;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.row_tkk1 {
  font-size: 14px;
  width: 27px;
  height: 27px;
  line-height: 27px;
  color: #000;
  border-radius: 50%;
  background: rgba(255, 255, 255, 1);
  background: -moz-radial-gradient(
    center,
    ellipse cover,
    rgba(255, 255, 255) 0%,
    #fdc6c6 100%
  );
  background: -webkit-gradient(
    radial,
    center center,
    0,
    center center,
    100%,
    color-stop(0%, rgba(255, 255, 255, 1)),
    color-stop(100%, rgba(255, 203, 125, 1))
  );
  background: -webkit-radial-gradient(
    center,
    ellipse cover,
    rgba(255, 255, 255) 0%,
    #fdc6c6 100%
  );
  background: -o-radial-gradient(
    center,
    ellipse cover,
    rgba(255, 255, 255) 0%,
    #fdc6c6 100%
  );
  background: -ms-radial-gradient(
    center,
    ellipse cover,
    rgba(255, 255, 255) 0%,
    #fdc6c6 100%
  );
  background: radial-gradient(
    ellipse at center,
    rgba(255, 255, 255) 0%,
    #fdc6c6 100%
  );
  filter: progid:DXImageTransform.Microsoft.gradient(startColorstr='#ffffff',endColorstr='#ffcb7d',GradientType=1);
}

.row_tkk2 {
  font-size: 11px;
}

.table.table-col3 td,
.table.table-col3 th {
  padding: 5.5px 3px;
}

.bgaliceblue {
  background: #f0f8ff;
}

.view-more {
  float: left;
  width: 100%;
  text-align: center;
  padding: 12px 16px;
}

.view-more2 {
  float: left;
  width: 100%;
  text-align: center;
}

.font-tks {
  font-size: 18px;
}

.hidden-xs {
  width: 15%;
}

.tabKeno {
  float: left;
  display: none;
  width: 100%;
  margin-bottom: 12px;
}

.tabKeno li {
  display: inline-block;
  padding: 3px 10px;
  background: #f2f2f2;
  border-radius: 5px 5px 0 0;
  font-weight: 700;
}

.tabKeno li.active {
  background: #cf0034;
  color: #fff;
}

.ic-btnkeno.keno-hoa {
  background: #fff;
}

.ic-btnkeno.keno-hoa::after {
  content: "-";
  font-size: 20px;
  color: #4caf50;
  font-weight: bold;
}

.red {
  color: #ed1c25;
}

.content-tabkeno {
  width: 100%;
  margin-bottom: 16px;
  float: left;
  font-size: 15px;
  line-height: 1.6;
}

.content-tabkeno p {
  padding: 6px 0;
}

.row-cl.fixclo2 {
  width: 23%;
  float: left;
  margin: 0 1%;
}

.keno-rowcl {
  float: left;
  width: 100%;
}

.keno-pad {
  padding: 16px 16px 0 16px;
}

.flex-table {
  display: table;
}

.div-col {
  display: table-cell;
  padding: 0 12px;
  text-align: center;
}

.bdfont {
  font-size: 13px;
  line-height: normal;
}

.div-rw {
  float: left;
  margin-bottom: 8px;
  width: 100%;
  position: relative;
}

.fix-bieudo {
  float: left;
  width: 100%;
  height: 0;
  background-color: #505edc;
}

.item-popup1 {
  float: left;
  margin-right: 10px;
}

.popup-bg {
  background: #fff;
  position: fixed;
  display: none;
  z-index: 9999;
  height: 100%;
  width: 100%;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  overflow-y: auto;
  overflow-x: hidden;
  align-items: flex-start;
  justify-content: center;
}

.popup-wrapper {
  background: #ffeca0;
  display: flex;
  max-width: 1080px;
  height: 600px;
  top: 0;
  margin: auto;
  transform: scale(1920px);
  position: relative;
}

.popup-body {
  float: left;
  width: 100%;
}

.popup-header {
  float: left;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
  display: flex;
}

.popup-title {
  float: left;
}

.close-popup {
  position: absolute;
  right: 5px;
  top: 5px;
  z-index: 999;
  width: 36px;
  height: 36px;
  text-align: center;
  cursor: pointer;
  border-radius: 100%;
  border: solid 1px #812929;
}

.close-popup img {
  position: relative;
  top: 5px;
}

.popup-content {
  float: left;
  width: 100%;
}

.entry-popup {
  float: left;
  width: 100%;
}

.target-hidden {
  display: none !important;
}

.target-expanded {
  display: block !important;
}

.overflow-hidden {
  overflow: hidden;
}

.col2_item {
  width: 50%;
  float: left;
}

.col2-keno {
  width: 49.5%;
  float: left;
  margin-right: 1%;
}

.col2-keno:last-child {
  margin-right: 0;
}

.keno_logo {
  float: left;
}

.keno_title {
  float: left;
}

.keno_ky {
  float: right;
  color: #90191c;
  font-size: 16px;
  font-weight: 700;
}

.keno_title {
  color: #90191c;
  font-size: 40px;
  font-weight: 700;
}

.col2-keno .table.keno_tructiep td,
.col2-keno .table.keno_tructiep th {
  padding: 0;
}

.col2-keno .cellKeno {
  line-height: 29px;
  height: 29px;
}

.col2-keno .ic-btnkeno {
  position: relative;
}

.col2-keno .ic-btnkeno-lonbe {
  position: relative;
}

.ic-btnkeno.keno-icLon {
  background: #f26531;
  position: relative;
}

.ic-btnkeno.keno-icBe {
  background: #faa21e;
  position: relative;
}

.ic-btnkeno.keno-icLon::after {
  position: relative;
  width: 24px;
  height: 24px;
  line-height: 24px;
  color: #fff;
  text-align: center;
  border-radius: 50%;
  left: 0;
  content: ">";
  font-size: 16px;
}

.ic-btnkeno.keno-icBe::after {
  position: relative;
  width: 24px;
  height: 24px;
  line-height: 24px;
  color: #fff;
  text-align: center;
  border-radius: 50%;
  left: 0;
  content: "<";
  font-size: 16px;
}

@media only screen and (max-width: 800px) {
  .div-col {
    padding: 0 6px;
  }

  .row-cl.fixclo2 {
    width: 48%;
    float: left;
    margin: 0.5% 1%;
  }

  .col2-keno {
    width: 100%;
    float: left;
    margin-right: 0;
  }

  .keno_ky {
    font-size: 14px;
  }

  .keno_title {
    font-size: 18px;
  }

  .tabKeno {
    display: block;
  }

  #tab_Keno_id1 {
    display: none;
  }

  #tab_Keno_id2 {
    display: none;
  }

  .item-c-l {
    width: auto;
    padding: 0 12px;
    height: 24px;
    line-height: 24px;
    font-size: 14px;
    font-weight: 400;
  }

  .live-font18 {
    font-size: 16px;
  }

  .row-cl {
    font-size: 14px;
  }

  .font-tks {
    font-size: 16px;
  }

  .row_tkk2 {
    font-size: 2.4vw;
    margin-top: 1vw;
    font-weight: normal;
  }

  .content-left-page {
    width: 100%;
  }

  .hidden-xs {
    display: none;
  }

  .span-row {
    display: inline-block;
  }

  .btn-number-live {
    width: 8vw;
    height: 8vw;
    line-height: 8.3vw;
    font-size: 5vw;
  }

  .layout-grid-10 {
    grid-column-gap: 3px;
    grid-row-gap: 3px;
  }

  .keno-box1 {
    width: 100%;
    margin-bottom: 12px;
  }

  .keno-box2 {
    width: 100%;
    padding: 0;
    float: left;
    margin-bottom: 12px;
  }

  .keno-col1 {
    float: left;
    width: 100%;
    margin-bottom: 8px;
  }

  .keno-col2 {
    float: left;
    width: 100%;
    margin-bottom: 12px;
  }

  .keno-col3 {
    float: left;
    width: 100%;
  }

  .kenno-btn-kq {
    padding: 0;
    grid-column-gap: 3px;
    grid-row-gap: 5px;
  }

  .section-content.pad16px {
    padding: 10px;
  }
}

@media only screen and (max-width: 480px) {
  .btn-number-kq {
    height: 28px;
    line-height: 28px;
    width: 28px;
    font-size: 14px;
  }

  .box-grid10 {
    grid-template-columns: repeat(6, 1fr);
  }

  .bang-tkkn {
    margin-bottom: 12px;
  }
}

@media only screen and (max-width: 359px) {
  .btn-number-kq {
    height: 24px;
    line-height: 24px;
    width: 24px;
    font-size: 14px;
  }
}

.btn-number-kq.bg-active {
  background: #c80505;
  color: #fff;
  border: solid 1px #c80505;
}

input,
select,
textarea {
  float: left;
  width: 100%;
  border: solid 1px #b3b3b3;
  margin: 0;
  box-shadow: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  -o-appearance: none;
  text-overflow: "";
  appearance: none;
  border-radius: 2px;
  webkit-border-radius: 2px;
  -moz-border-radius: 2px;
}

.the-article-content ul {
  margin: 12px 0;
  padding-left: 16px;
}

.the-article-content li {
  list-style: disc;
  padding: 5px 0;
}

::marker {
  unicode-bidi: isolate;
  font-variant-numeric: tabular-nums;
  text-transform: none;
  text-indent: 0 !important;
  text-align: start !important;
  text-align-last: start !important;
}

.code-DB10,
.code-DB11,
.code-DB12,
.code-DB13,
.code-DB14,
.code-DB15,
.code-DB16,
.code-DB17,
.code-DB18,
.code-DB11,
.code-DB11 {
  float: left;
  height: 40px;
  line-height: 40px;
  color: #ed1c25;
  font-weight: 700;
}

.headpn {
  height: 50px;
  width: 100%;
  float: left;
}

.menu_ul li:hover > .menu_down {
  visibility: visible;
  opacity: 1;
}

@media (hover: none) {
  .menu_ul li:hover > .menu_down {
    visibility: hidden;
  }
}

.content-pad-12 {
  width: 100%;
  float: left;
  padding: 12px;
}

.block {
  width: 100%;
  float: left;
  margin-bottom: 16px;
}

.scroll-horizontal {
  position: relative;
  width: 100%;
  z-index: 1;
  overflow-x: scroll;
  border: solid 1px #ddd;
}

.table-fixed.bg-hover .bg-tet:hover {
  color: #222;
}

.bg-beige {
  background: #fffbea;
}

.font-bold {
  font-weight: bold;
}

.block-form {
  width: 100%;
  float: left;
  background: #e4e4e4;
  padding: 8px 10px;
}

.title-block {
  font-size: 18px;
  font-weight: bold;
}

.title-block-2 {
  font-size: 16px;
  font-weight: bold;
}

.title-sta {
  margin-bottom: 10px;
}

.ul-statistic {
  padding: 0;
  width: 100%;
  float: left;
}

.ul-statistic li {
  float: left;
  width: 100%;
  padding: 5px 0 5px 16px;
  position: relative;
}

.ul-statistic li::before {
  border-top: 4px dashed;
  border-right: 4px solid transparent;
  border-left: 4px solid transparent;
  content: "";
  position: absolute;
  left: 0;
  top: 15px;
  color: #444;
  -webkit-transform: rotate(-90deg);
  -moz-transform: rotate(-90deg);
  -ms-transform: rotate(-90deg);
  -o-transform: rotate(-90deg);
  transform: rotate(-90deg);
}

.td-w5 {
  width: 5%;
}

.td-w10 {
  width: 10%;
}

.td-w15 {
  width: 15%;
}

.td-w20 {
  width: 20%;
}

.td-w25 {
  width: 25%;
}

.td-w30 {
  width: 30%;
}

.td-w35 {
  width: 35%;
}

.td-w40 {
  width: 40%;
}

.td-w45 {
  width: 45%;
}

.td-w50 {
  width: 50%;
}

.td-w55 {
  width: 55%;
}

.td-w60 {
  width: 60%;
}

.td-w65 {
  width: 65%;
}

.td-w70 {
  width: 70%;
}

.td-w75 {
  width: 75%;
}

.td-w80 {
  width: 80%;
}

.td-w85 {
  width: 85%;
}

.td-w90 {
  width: 90%;
}

.td-w95 {
  width: 95%;
}

.ul-tab {
  display: table;
  width: 100%;
  background: #f0f8ff;
  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16), 0 2px 10px 0 rgba(0, 0, 0, 0.12);
  -webkit-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16),
    0 2px 10px 0 rgba(0, 0, 0, 0.12);
  -moz-box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.16),
    0 2px 10px 0 rgba(0, 0, 0, 0.12);
}

.ul-tab li {
  display: table-cell;
  text-align: center;
  border-left: solid 1px #ddd;
}

.ul-tab li:first-child {
  border-left: 0;
}

.ul-tab li a {
  display: block;
  padding: 8px 6px;
  font-weight: 500;
  font-size: 14px;
}

.ul-tab li a.active {
  background: #ef1c25;
  color: #fff;
}

.number-DB.bg-cn {
  background: #96c9f4;
}

.table-fixed tfoot,
.table-fixed tfoot th,
.table-fixed tfoot td {
  position: -webkit-sticky;
  position: sticky;
  bottom: 0;
  background: #dbebf8;
  z-index: 4;
}

.lottery-info table {
  font-size: 13px;
}

.headspecial,
.tailspecial,
.specialvalue,
.sumspecial,
.sumspecialeven,
.sumspecialodd {
  display: none;
}

.bg-faf2e3 {
  background: #faf2e3;
}

.result-flex {
  width: 100%;
  display: flex;
}

.table-skq1 {
  width: 67%;
  float: left;
}

.table-skq2 {
  width: 33%;
  float: left;
  padding-left: 10px;
}

.font-skq .prize1,
.font-skq .prize2,
.font-skq .prize3,
.font-skq .prize4,
.font-skq .prize5,
.font-skq .prize6,
.font-skq .prize8 {
  font-size: 24px;
}

.font-skq .special-prize {
  font-size: 28px;
}

.font-skq .prize7 {
  font-size: 28px;
}

.table-result.font-skq thead th,
.table-loto.font-skq thead .headerloto th {
  font-size: 16px;
  padding: 6px 3px;
  font-weight: bold;
  background: #ffeca0;
}

.table-loto.font-skq thead th {
  color: #c80406;
  font-weight: bold;
  padding: 6px 3px;
}

.table-loto.font-skq td {
  padding: 6px 3px;
}

.font-title-skq {
  font-weight: bold;
  font-size: 16px;
}

.ul-tabs-link {
  display: table;
  width: 100%;
  border: solid 1px #ddd;
  background: #f0f8ff;
}

.ul-tabs-link li:first-child {
  border-left: 0;
}

.ul-tabs-link li {
  display: table-cell;
  border-left: solid 1px #ddd;
  text-align: center;
}

.ul-tabs-link li a {
  display: block;
  padding: 8px 3px;
  font-size: 14px;
}

.ul-tabs-link li a.active {
  color: #fff;
  background: #00b1e8;
}

.col-inline {
  display: inline-block;
  margin-right: 12px;
  vertical-align: top;
}

.label-inline {
  display: inline-block;
  width: 100%;
  margin-bottom: 8px;
}

.w-touchspin {
  display: flex;
  width: 260px;
}

.input-spinner {
  text-align: center;
  width: 100%;
  padding: 0;
}

.btn-touchspin {
  height: 40px;
  line-height: 40px;
  border: solid 1px #ddd;
  padding: 0 12px;
  background: #fff;
  cursor: pointer;
  text-align: center;
  border-radius: 2px;
  outline: none;
  display: inline-block;
}

.btn-touchspin.touchspin-postfix {
  line-height: 36px;
  background: #f2f2f2;
}

.bg-info {
  background-color: #d9edf7;
}

.btn-tslt {
  background: #fff;
  display: inline-block;
  height: 32px;
  line-height: 32px;
  font-size: 12px;
  cursor: pointer;
  text-align: center;
  outline: none;
  padding: 0 8px;
  border-radius: 2px;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
}

.btn-tslt.active {
  background: #959595;
  color: #fff;
}

.btn-tk {
  padding: 2px;
  font-size: 12px;
  border-radius: 3px;
  border: solid 1px #ddd;
  display: inline-block;
  min-width: 40px;
  cursor: pointer;
  font-weight: normal;
  background: #fff;
}

.btn-tk:hover {
  background: #ddd;
}

.table-tkth td,
.table-tkth th {
  border: 0;
}

.table-tkth tr {
  border-bottom: solid 1px #ddd;
}

.table-tkth {
  border: solid 1px #ddd;
}

.label-checkbox {
  display: block;
  position: relative;
  cursor: pointer;
}

.label-checkbox .input-checkbox {
  position: absolute;
  z-index: -1;
  opacity: 0;
  display: none;
  margin: 0;
  padding: 0;
  height: 0;
  width: 0;
  border: 0;
}

.checkbox-indicator {
  height: 16px;
  width: 16px;
  border-radius: 2px;
  border: solid 1px #e0e0e0;
  display: inline-block;
  position: relative;
  top: 2px;
}

.label-checkbox:hover .input-checkbox ~ .checkbox-indicator,
.label-checkbox .input-checkbox:focus ~ .checkbox-indicator {
  background: #fff;
}

.label-checkbox .input-checkbox:checked ~ .checkbox-indicator {
  background: #06f;
  border: solid 1px #06f;
}

.label-checkbox:hover
  .input-checkbox:not([disabled]):checked
  ~ .checkbox-indicator,
.label-checkbox .input-checkbox:checked:focus ~ .checkbox-indicator {
  background: #06f;
}

.label-checkbox .input-checkbox:disabled ~ .checkbox-indicator {
  background: #f2f2f2;
  opacity: 0.6;
  pointer-events: none;
}

.checkbox-indicator:after {
  content: "";
  position: absolute;
  display: none;
}

.label-checkbox .input-checkbox:checked ~ .checkbox-indicator:after {
  display: block;
}

.label-checkbox .checkbox-indicator:after {
  left: 5px;
  top: 2px;
  width: 5px;
  height: 8px;
  border: solid #fff;
  border-width: 0 2px 2px 0;
  transform: rotate(45deg);
}

.label-checkbox .input-checkbox:disabled ~ .checkbox-indicator:after {
  border-color: #7b7b7b;
}

.color-red {
  color: #ed1c25;
}

.w_5 {
  width: 5%;
}

.w_10 {
  width: 10%;
}

.w_15 {
  width: 15%;
}

.w_20 {
  width: 20%;
}

.w_25 {
  width: 25%;
}

.w_30 {
  width: 30%;
}

.w_35 {
  width: 35%;
}

.w_40 {
  width: 40%;
}

.w_45 {
  width: 45%;
}

.w_50 {
  width: 50%;
}

.w_60 {
  width: 60%;
}

.w_70 {
  width: 70%;
}

.w_80 {
  width: 80%;
}

.w_90 {
  width: 90%;
}

.lottery-info ul {
  margin: 10px 0;
  padding-left: 32px;
}

.lottery-info ul li {
  list-style: disc;
}

.font-madb {
  font-size: 14px;
  font-weight: bold;
  display: block;
  text-align: center;
}

.font-madb span {
  display: inline-block;
  margin: 0 5px;
}

.table-result.font-skq tr:nth-of-type(2n) {
  background-color: #f9f9f9;
}

.table-loto.font-skq tr:nth-of-type(2n) {
  background-color: #f9f9f9;
}

.title-row {
  float: left;
  width: 100%;
  background: #ffeca0;
  padding: 6px 16px;
  text-align: center;
  font-size: 16px;
  font-weight: bold;
}

.head-th {
  font-size: 16px;
  font-weight: bold;
}

.bg-hover .number-DB:hover {
  background: #ffeca0;
}

.bg-hover .number-DB.active {
  background: #ffeca0;
}

.content-left2 {
  width: 71%;
  float: left;
}

.btn-danger.mgtop8 {
  margin-top: 8px;
}

.btn-tabs {
  display: inline-block;
  padding: 0 16px;
  height: 40px;
  font-size: 14px;
  line-height: 40px;
  background-color: #dddfe2;
  cursor: pointer;
  text-align: center;
  border-radius: 2px;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
}

.btn-tabs.active {
  background-color: #095088;
  color: #fff;
}

.table-scroll-tkts {
  position: relative;
  width: 100%;
  z-index: 1;
  margin: auto;
  overflow-x: scroll;
}

.table.table-tkts {
  border: solid 1px #666;
}

.table-fixed thead th:first-child {
  z-index: 5;
}

.table.table-tkts th {
  border: solid 1px #666;
  padding: 4px;
  width: 50px;
}

.table-scroll-tkts th:first-child {
  position: -webkit-sticky;
  position: sticky;
  left: 0;
  z-index: 2;
  background: #d9edf7;
}

.table-scroll-tkts thead th {
  background: #d9edf7;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
}

.table.table-tkts td {
  border: solid 1px #666;
  width: 25px;
  padding: 0;
  height: 25px;
}

.darkgb {
  background-color: #808080;
}

.tansuatrow {
  background: #fff;
}

.tansuatrow:hover {
  background: #ffeaa6;
}

.tansuatrow:hover .bg-info {
  background: #ffeaa6;
}

tr.tansuatrow.bg-hover > td:not(.darkgb) {
  background-color: #ffeaa6;
}

tr.tansuatrow.bg-active > td:not(.darkgb) {
  background-color: #ffbf00;
}

tr.tansuatrow.bg-active .bg-info {
  background-color: #ffbf00;
}

.tansuat-verti .kqbackground.verti-highlight.verti-active {
  background-color: #ffbf00;
}

.tansuat-verti .verti-highlight.verti-hover {
  background-color: #ffe2b3;
}

.tr-hover:hover {
  background-color: #f2f2f2;
}

.bg-warning {
  background-color: #fcf8e3;
}

.bg-success {
  background-color: #dff0d8;
}

.fn-loto {
  font-weight: bold;
}

.div-text-bs {
  background: #d9edf7;
  float: left;
  width: 100%;
  padding: 8px;
  border: solid 1px #e0e0e0;
  text-align: center;
  font-size: 16px;
  font-weight: bold;
}

.table.table-tktslt {
  float: left;
  font-size: 16px;
  width: 32%;
  text-align: center;
  margin: 1%;
  border: solid 1px #ccc;
}

.table.table-tktslt:first-child {
  margin-left: 0;
}

.table.table-tktslt:last-child {
  margin-right: 0;
}

.table.table-tktslt td {
  padding: 4px;
}

.table.table-tktslt td:first-child {
  width: 30%;
}

.table-gan {
  width: auto;
  display: inline-block;
  position: relative;
  margin-top: 20px;
  margin-right: 5px;
}

.table-gan td {
  vertical-align: bottom;
}

.gandiv {
  float: left;
  text-align: center;
  width: 30px;
}

.gantext1 {
  width: 100%;
  float: left;
  font-size: 12px;
  margin-bottom: 5px;
}

.gan-bkg {
  float: left;
  width: 100%;
  background: #227dcc;
  margin-bottom: 5px;
  height: 1px;
}

.gantext2 {
  float: left;
  width: 100%;
  font-size: 16px;
  font-weight: bold;
  color: #e1203d;
}

.table.table-tknum {
  border: solid 1px #ccc;
  background: #f0f8ff;
}

.font-blod {
  font-weight: bold;
}

.bg-tet {
  background: #e70700 !important;
  font-weight: bold;
  color: #fff !important;
}

.giaidacbiet {
  float: left;
  width: 100%;
}

@media only screen and (max-width: 800px) {
  .font-skq .prize1,
  .font-skq .prize2,
  .font-skq .prize3,
  .font-skq .prize4,
  .font-skq .prize5,
  .font-skq .prize6,
  .font-skq .prize8 {
    font-size: 18px;
  }

  .font-skq .special-prize {
    font-size: 22px;
  }

  .font-skq .prize7 {
    font-size: 22px;
  }

  .table-loto.font-skq td {
    font-size: 12px;
  }

  .table-skq2 {
    float: left;
    padding-left: 0;
  }

  .table-loto.font-skq td {
    padding: 2px 3px;
  }

  .table-loto.font-skq thead th {
    padding: 7px 3px;
  }

  .col-inline.m-full {
    margin-right: 0;
    width: 100%;
    margin-bottom: 12px;
  }

  .m-scrol {
    overflow: auto;
    white-space: nowrap;
  }

  .table-result.font-skq thead th {
    font-size: 14px;
    padding: 7px 3px;
  }

  .head-th {
    font-size: 14px;
  }

  .content-left2 {
    width: 100%;
    margin-bottom: 16px;
  }

  .m-calc {
    margin-left: -12px !important;
    margin-right: -12px !important;
    width: calc(100% + 24px) !important;
  }

  .w-touchspin {
    width: 100%;
  }

  .btn-tabs {
    padding: 0 8px;
    height: 36px;
    line-height: 36px;
  }
}

.table-result th a {
  text-decoration: underline;
}

.verti-active {
  background-color: #ffbf00 !important;
}

.fn-loto-2 {
  font-weight: bold;
  font-size: 18px;
}

.bkg-red {
  background: #e1203d;
  color: #fff;
  font-weight: bold;
}

.w75 {
  width: 75px;
}

.hideall {
  display: none;
}

.tdDB-hover .number-DB:hover {
  background: #098852;
  color: #fff;
}

.tdDB-hover .number-DB.active {
  background: #098852;
  color: #fff;
}

.tdDB-hover .number-DB.active .color_red {
  color: #fff;
}

.tdDB-hover .number-DB:hover .color_red {
  color: #fff;
}

.tdbg-hover tbody td:hover {
  background: #ffe2b3;
}

.tdtkDB-hover tbody td:hover {
  background: #ffe2b3;
}

.table-loto.font-skq tr:hover {
  background: #fff4c3;
}

.mauxanh_active,
.mauxanh_active div span,
.mauxanh_activet,
.mauxanh_activet div span,
.mauxanh_actived,
.mauxanh_actived div span {
  background: #098852;
  color: #fff;
}

.bg-beige1 div span:hover,
.mauxanh_activet div span:hover,
.mauxanh_actived div span:hover {
  background: #098852 !important;
  color: #fff !important;
}

.mauxanh_active1 {
  background: #098852 !important;
  color: #fff;
}

.bg-beige1,
.bg-beige1 div span {
  background: #fff6d0;
  color: #000;
}

@media only screen and (max-width: 800px) {
  .ul-tabs-link.m-row2 {
    display: grid;
    grid-template-columns: repeat(6, 1fr);
  }

  .ul-tabs-link li {
    border-bottom: solid 1px #ddd;
  }

  .ul-tabs-link.m-row2 li a {
    font-size: 13px;
    padding: 6px 2px;
  }
}

.trbg-hover tbody tr:hover {
  background: #ffe2b3;
}

#btnStartOrStop {
  text-wrap: nowrap;
}

.caleandar-days{
  border-left: 1px solid #e0e0e0;
}

.caleandar-days li{
  border-right: 1px solid #e0e0e0;
  border-bottom: 1px solid #e0e0e0;
  border-left: none !important;
  border-top: none !important;
}

.caleandar-weks {
  border-bottom: 1px solid #e0e0e0;
  border-right: 1px solid #e0e0e0;
}