.dashboardContainer {
    padding: 20px;
    background-color: #f4f4f4;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin: 20px;
    display: grid;
    grid-template-columns: 1fr 1fr;
  }
  
  .header {
    text-align: center;
    color: #333;
    margin-bottom: 20px;
  }
  
  .title {
    font-size: 2rem;
    color: #007bff;
  }
  
  .subtitle {
    font-size: 1.5rem;
    color: #666;
  }
  

  

  @media (max-width: 768px) {
    .dashboardContainer {
      display: flex;
      flex-direction: column;
      margin-top: 20px;
    }

    .adminPrizeTable {
      margin-top: 20px;
      max-width: 100%;
    }
    
 }