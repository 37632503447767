.flex-row {
    display: flex;
    margin: 0 !important;
    padding: 0 !important;
}


.prize-row {
    min-height: 40px;
    border-top: solid 1px #dddfe2;
    border-right: solid 1px #dddfe2;
    font-family: arial;
}

.prize-row:last-child {
    border-bottom: solid 1px #dddfe2;
}


.prize-row .prize-name {
    min-height: 40px;
    width: 12%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-left: solid 1px #dddfe2;
    border-right: solid 1px #dddfe2;
}


.grid-3 {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    width: 100%;
}

.prize-number {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 40px;
    width: 100%;
    line-height: 40px;
    font-size: 28px;
    font-weight: bold;
}

.prize-number:hover {
    background-color: #fff4c3;
    
}

.color-red {
    color: #ed1c25;
}

.color-red .prize-number {
    font-size: 34px
}

.color-red.size-30 .prize-number {
    font-size: 30px
}

.bg-blue-light {
    background-color: #f0f8ff;
}

.spinning-ball {
    width: 20px; /* Adjust the size as needed */
    height: 20px; /* Adjust the size as needed */
    background: url("../images/long-quay.gif") no-repeat center
      center; /* Update with your ball image */
    background-size: cover;
    animation: spin 2s linear infinite;
    position: relative;
}

#hover-number {
    height: 30px;
    border-top: solid 1px #dddfe2;
    border-right: solid 1px #dddfe2;
    border-bottom: solid 1px #dddfe2;
}

  
#hover-number div{
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 3px;
    border-left: solid 1px #dddfe2;
    width: 100%;
}

#hover-number div:hover{
    background-color: #00918d;
}

.number-spin {
    display: flex;
    justify-content: center;
    border-top: solid 1px #dddfe2;
}


/* Responsive cho thiết bị di động */
@media (max-width: 768px) {
    .prize-number {
        font-size: 24px !important;
    }
    .color-red .prize-number {
        font-size: 26px !important;
    }
    
 }
  
@media (max-width: 480px) {
    .spinning-ball {
        width: 16px;
        height: 16px;
    }

    .prize-number {
        font-size: 18px !important;
    }

    .color-red .prize-number {
        font-size: 22px !important;
    }
}
  